import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

export default class Multimedia extends Component {
    constructor() {
        super();
    }

    render() {
        return (
            <div className='container'>
                <Helmet>
                    <meta name="description" content="Zarządzanie multimediami w domu" />
                    <meta name="keywords" content="inteligentny dom, multimedia" />
                    <link rel="canonical" href="https://raz-dwa.pl/inteligentne-domy/funkcjonalnosci/multimedia" />
                    <title>Raz Dwa - Inteligentne domy - Multimedia</title>
                </Helmet>

                <section>
                    <p className='h3'>Multimedia</p>
                    <p>Domowy sprzęt pod pełną kontrolą</p>
                    <p>Wiele urządzeń audio ma możliwość sterowania oraz integracji z systemem Smart Home. Korzystając z modułów AMPIO stworzysz system nagłośnienia w całym domu i połączysz go z dowolnym sprzętem audio czy TV.</p>
                    <p>Na panelu dotykowym czy ekranie aplikacji przełączysz inteligentny dom w zaprogramowaną scenę "Kino". Jednym dotknięciem zmienisz oświetlenie, włączysz nagłośnienie i zasłonisz żaluzje.</p>

                    <div className='gridStack withoutButton'>
                        <div className='item'>
                            <div className='left'>
                                <img src='https://p2p6d7w4.rocketcdn.me/wp-content/uploads/2021/03/AdobeStock_134003183-1.png' alt=''/>
                            </div>
                            <div className='right'>
                                <h2>Muzyka zawsze wokół Ciebie</h2>
                                <p>Słuchaj ulubionej stacji radiowej - tam gdzie akurat się znajdujesz, niech muzyka podąża za Tobą. Jednym dotknięciem uruchom kino domowe, przygaś oświetlenie i zasłoń rolety. Moduł Audio umożliwi integracje systemów audio - video w Twoim domu z całym systemem Ampio Smart Home.</p>
                            </div>
                        </div>
                        <div className='item reverse'>
                            <div className='left'>
                                <img src='https://p2p6d7w4.rocketcdn.me/wp-content/uploads/2021/03/AdobeStock_308965757.png' alt=''/>
                            </div>
                            <div className='right'>
                                <h2>Sterowanie dziesiątkami urządzeń</h2>
                                <p>Stworzyliśmy dla Ciebie system o nieograniczonych możliwościach integracji, dzięki zaawansowanej technologii możesz rozwijać go i usprawniać podłączając kolejne urządzenia. Możesz sterować całą gamą nowoczesnego sprzętu domowego, nie tylko audio-wideo, ale RTV, a nawet AGD.</p>
                            </div>
                        </div>
                        <div className='item'>
                            <div className='left'>
                                <img src='https://p2p6d7w4.rocketcdn.me/wp-content/uploads/2021/03/AdobeStock_296388747.png' alt=''/>
                            </div>
                            <div className='right'>
                                <h2>Scena idealna do sytuacji</h2>
                                <p>Przyciemnione światła i opuszczone rolety kiedy włączasz kino domowe czy ulubiona stacja radiowa w łazience i odsłonięte żaluzje gdy tylko wstaniesz. Podłącz różne urządzenia audio-video do systemu Ampio, aby ich obsługa była przyjemniejsza niż kiedykolwiek.</p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}