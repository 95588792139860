import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

export default class Certificates extends Component {
    constructor() {
        super();
    }

    render() {
        return (
            <div className='container'>
                <Helmet>
                    <meta name="robots" content="noindex, nofollow" />
                    <title>Raz Dwa - Polityka prywatności</title>
                </Helmet>

                <section>
                    <p className='h3'>Polityka prywatności</p>
                    <p>W Polityce Prywatności zawarliśmy informacje, co dzieje się z Twoimi danymi, które zostawisz podczas wizyty na naszej stronie. Możesz być pewny, że Twoje dane są chronione.</p>
                    <p>RAZ DWA w trosce o bezpieczeństwo Państwa danych osobowych i w wykonaniu obowiązków przewidzianych w Rozporządzeniu Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (dalej RODO), mając na względzie art. 12 i 13 RODO, informuje jak następuje poniżej.</p>
                    <p>Polityka bezpieczeństwa stanowi zbiór zasad korzystania z serwisu www.raz-dwa.pl i informacji odnośnie ochrony Państwa danych osobowych.</p>
                    <p><strong>I. Pliki „Cookies”</strong></p>
                    <ol>
                        <li>Dla Państwa wygody nasz serwis używa plików Cookies w celu dostosowania strony do potrzeb użytkowników i w celach statystycznych. Pliki Cookies to niewielkie pliki tekstowe wysyłane przez odwiedzany przez Państwa serwis internetowy. Są one następnie zapisywane w Państwa urządzeniu końcowym.</li>
                        <li>Serwis stosuje Cookies służące do zbierania informacji o sposobie korzystania ze strony oraz Cookies pozwalające „zapamiętać” ustawienia wybranych przez Państwa preferencji strony (np. język, rozmiar czcionki).</li>
                        <li>W przeglądarce internetowej można zmienić ustawienia dotyczące Cookies. Brak zmiany tych ustawień oznacza akceptację dla ich stosowania.<br />4. W każdej chwili mają Państwo prawo do wyłączenia plików Cookies. Szczegółowe informacje o tym, jak to zrobić znajdują się TU: <a href="http://jakwylaczyccookie.pl/" rel="noindex">http://jakwylaczyccookie.pl/</a></li>
                    </ol>
                    <p><strong>II. Dane osobowe.</strong></p>
                    <ol>
                        <li>Administratorem Państwa danych osobowych, po przesłaniu formularza kontaktowego i zaakceptowaniu Polityki prywatności, jest RAZ DWA z siedzibą w Gogolinku. Podanie danych jest w pełni dobrowolne.</li>
                        <li>We wszelkich sprawach dotyczących przetwarzania Pani/Pana danych osobowych przez Podmiot oraz korzystania z praw związanych z przetwarzaniem danych należy kontaktować się z RAZ DWA z siedzibą w Gogolinku 28, e-mail: <a href="mailto:biuro@razdwa.pl">biuro@razdwa.pl</a> z dopiskiem „Dane osobowe”.</li>
                        <li>Państwa dane osobowe będą przetwarzane przez Administratora wyłącznie w celach:<br />- wysyłki Newslettera (podstawa z art. 6 ust. 1 lit. f) RODO),<br />- przesyłania wiadomości zawierających informacje handlowe (podstawa z art. 6 ust. 1 lit. f) RODO),<br />- prowadzenia marketingu podmiotów współpracujących wyłącznie w ramach RAZ DWA z siedzibą w Gogolinku (podstawa z art. 6 ust. 1 lit. f) RODO)</li>
                        <li>Podane dane osobowe nie będą udostępniane podmiotom trzecim, z wyjątkami przewidzianymi prawem.</li>
                        <li>Podane dane nie będą przekazywane do państwa trzeciego.</li>
                        <li>Dane osobowe będą przechowywane przez okres nie dłuższy, niż jest to niezbędne do celów, w których dane te są przetwarzane;</li>
                        <li>Podanie danych jest w pełni dobrowolne, jednocześnie jest gwarantem świadczenia naszych usług. Oznacza, również zgodę na przetwarzanie danych osobowych dobrowolnie podanych.</li>
                        <li>Administrator oświadcza, iż dołożył należytej staranności w celu odpowiedniego zabezpieczenia przekazanych danych osobowych, a w szczególności przed ich udostępnieniem osobom nieupoważnionym.</li>
                        <li>W każdej chwili istnieje możliwość odwołania zgody na przetwarzanie danych osobowych. Po odwołaniu takiej zgody Państwa dane zostaną usunięte z bazy Administratora i nie będą dalej przetwarzane. Wycofanie zgody nie wpływa na zgodność z prawem przetwarzania, którego dokonano na podstawie zgody przed jej cofnięciem.</li>
                        <li>Mają Państwo prawo do złożenia sprzeciwu w sprawie przetwarzania Państwa danych w celach marketingowych oraz do wniesienia skargi do Prezesa Urzędu Ochrony Danych Osobowych.</li>
                        <li>Każdej osobie, której dotyczą dane (podmiotowi danych) przysługuje prawo dostępu do treści swoich danych oraz prawo ich sprostowania, żądania usunięcia, ograniczenia przetwarzania, prawo wniesienia sprzeciwu wobec przetwarzania danych, a także pro do przenoszenia danych</li>
                        <li>Państwa dane będą przetwarzane w sposób zautomatyzowany w tym również w formie profilowania. Zautomatyzowane podejmowanie decyzji będzie odbywało się na zasadach określonych w Instrukcji postępowania w zakresie bezpieczeństwa i ochrony danych osobowych.</li>
                    </ol>
                </section>
            </div>
        );
    }
}