import React from 'react';
import ReactDOM from 'react-dom';

import App from './components/App';

import './styles/_base.scss';

ReactDOM.render((
    <React.StrictMode>
        <App />
    </React.StrictMode>
), document.getElementById('app'));