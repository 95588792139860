import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';

export default class ElectricalInstallations extends Component {
    constructor() {
        super();
    }

    render() {
        return (
            <div className='container'>
                <Helmet>
                    <meta name="description" content="Uzyskane przez nas certyfikaty" />
                    <meta name="keywords" content="certyfikaty, fotowoltaika" />
                    <link rel="canonical" href="https://raz-dwa.pl/instalacje-elektryczne" />
                    <title>Raz Dwa - Instalacje elektryczne</title>
                </Helmet>

                <section>
                    <Redirect to='/instalacje-elektryczne/instalacje'/>
                </section>
            </div>
        );
    }
}