import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

export default class Security extends Component {
    constructor() {
        super();
    }

    render() {
        return (
            <div className='container'>
                <Helmet>
                    <meta name="description" content="Zabezpieczenia inteligentnego domu" />
                    <meta name="keywords" content="inteligentny dom, bezpieczeństwo, zabezpieczenia, monitoring" />
                    <link rel="canonical" href="https://raz-dwa.pl/inteligentne-domy/funkcjonalnosci/bezpieczenstwo" />
                    <title>Raz Dwa - Inteligentne domy - Bezpieczeństwo</title>
                </Helmet>

                <section>
                    <p className='h3'>Bezpieczeństwo</p>
                    <p>Bezpieczeństwo i monitoring w inteligentnym domu</p>
                    <p>Integracja wielu rozmaitych czujników z inteligentnym domem Ampio, zwiększy bezpieczeństwo Twoich bliskich. Czujniki ruchu w domu, ogrodzie, czujniki dymu, zalania oraz otwarcia drzwi stworzą jeden system bezpieczeństwa. Otrzymasz ostrzeżenie przed zagrożeniem, co pozwoli Ci odpowiednio zareagować, nawet gdy nie będzie Cię w domu.</p>

                    <div className='gridStack withoutButton'>
                        <div className='item'>
                            <div className='left'>
                                <img src='https://p2p6d7w4.rocketcdn.me/wp-content/uploads/2021/03/AdobeStock_332928144.png' alt=''/>
                            </div>
                            <div className='right'>
                                <h2>Integracja z czujnikami ruchu</h2>
                                <p>Czujniki ruchu w nocy pomogą Ci w poruszaniu się po mieszkaniu, uruchamiając oświetlenie. Jednak to oczywiście nie jedyna ich funkcja. Po integracji z systemem Ampio czujniki mogą służyć do wykrywania obecności domowników - oraz do poinformowania o wizycie nieproszonych gości.</p>
                            </div>
                        </div>
                        <div className='item reverse'>
                            <div className='left'>
                                <img src='https://p2p6d7w4.rocketcdn.me/wp-content/uploads/2021/03/AdobeStock_305792088.png' alt=''/>
                            </div>
                            <div className='right'>
                                <h2>Integracja z czujnikami dymu</h2>
                                <p>Możliwość podłączenia do systemu czujników dymu, tlenku węgla, gazu czy zalania to gwarancja bezpieczeństwa domowników. Zintegrowany system ostrzegania przed zagrożeniem poinformuje Cię błyskawicznie sygnałem dźwiękowym, zmianą koloru oświetlenia czy komunikatem w aplikacji. Wykrywając ulatnianie się gazu czy wyciek wody system zamknie odpowiedni zawór, chroniąc Ciebie i Twoich bliskich.</p>
                            </div>
                        </div>
                        <div className='item'>
                            <div className='left'>
                                <img src='https://p2p6d7w4.rocketcdn.me/wp-content/uploads/2021/03/AdobeStock_308964882-e1616694465330.png' alt=''/>
                            </div>
                            <div className='right'>
                                <h2>W pośpiechu</h2>
                                <p>Koniec zmartwień o niezamkniętych drzwiach, bramie czy garażu i to nawet przy pośpiesznym wyjściu? Tak, to możliwe. Ustaw automatyczne zamykanie w przypadku nieobecności domowników czy wyjazdu z garażu. W każdym momencie możesz sprawdzić, czy drzwi do mieszkania lub brama garażu pozostają zamknięte, a nawet kontrolować je zdalnie w aplikacji mobilnej.</p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}