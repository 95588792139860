import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

export default class KnowledgeZone extends Component {
    constructor() {
        super();
    }

    render() {
        return (
            <div className='container'>
                <Helmet>
                    <meta name="description" content="Zbiór wiedzy na temat ogrzewania" />
                    <meta name="keywords" content="ogrzewanie" />
                    <link rel="canonical" href="https://raz-dwa.pl/ogrzewanie/strefa-wiedzy" />
                    <title>Raz Dwa - Ogrzewanie - Strefa wiedzy</title>
                </Helmet>

                <section className='gridStack'>
                    <div className='container'>
                        <p className='h3'>Strefa wiedzy</p>
                        <div className='item'>
                            <div className='left'>
                                <img src={require('../../gfx/wiedza/how-much-it-costs.jpg')} alt='Ile to kosztuje' width='465' height='349' />
                            </div>
                            <div className='right'>
                                <h3><Link to='/ogrzewanie/strefa-wiedzy/ile-to-kosztuje'>Ile to kosztuje</Link></h3>
                                <p>Ogrzewanie Dream Heat jest atrakcyjną alternatywą dla istniejących rozwiązań grzewczych ze względu na niskie koszty inwestycyjne oraz możliwość obniżenia kosztów użytkowania do zera z fotowoltaiką.</p>
                                <button>Więcej</button>
                            </div>
                        </div>
                        <div className='item reverse'>
                            <div className='left'>
                                <img src={require('../../gfx/wiedza/fuel.jpg')} alt='Paliwa' width='465' height='349' />
                            </div>
                            <div className='right'>
                                <h3><Link to='/ogrzewanie/strefa-wiedzy/paliwa'>Paliwa</Link></h3>
                                <p>Budowa domu bez instalacji grzewczej w naszych warunkach klimatycznych jest niemożliwa. Warto zapoznać się z porównaniem dostępnych paliw by koszty maksymalnie ograniczyć, jednakże warto mieć na uwadze ekologiczne źródło energii.</p>
                                <button>Więcej</button>
                            </div>
                        </div>
                        <div className='item'>
                            <div className='left'>
                                <img src={require('../../gfx/wiedza/electric-methods.jpg')} alt='Porównanie elektrycznych metod ogrzewania' width='465' height='349' />
                            </div>
                            <div className='right'>
                                <h3><Link to='/ogrzewanie/strefa-wiedzy/porownanie-elektrycznych-metod-ogrzewania'>Porównanie elektrycznych metod ogrzewania</Link></h3>
                                <p>Podczas budowy domu warto zastanowić się czym ogrzejemy nasz dom. Dodatkowo elektryczną metodę ogrzewania wspomóc fotowoltaiką dzięki czemu obniżymy koszty ogrzewania.</p>
                                <button>Więcej</button>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}