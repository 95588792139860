import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

export default class ComparisonOfElectricHeatingMethods extends Component {
    constructor() {
        super();
    }

    render() {
        return (
            <div className='container'>
                <Helmet>
                    <meta name="description" content="Sprawdź zalety i wady elektrycznych metod ogrzewania" />
                    <meta name="keywords" content="porównanie elektrycznych metod ogrzewania" />
                    <link rel="canonical" href="https://raz-dwa.pl/ogrzewanie/strefa-wiedzy/porownanie-elektrycznych-metod-ogrzewania" />
                    <title>Raz Dwa - Ogrzewanie - Strefa wiedzy - Porównanie elektrycznych metod ogrzewania</title>
                </Helmet>

                <section>
                    <p className='h3'>Porównanie elektrycznych metod ogrzewania</p>
                    <div className='twoStack'>
                        <div className='item'>
                            <div className='left'>
                                <img src="https://dreamheat.eu/wp-content/uploads/2022/03/grzejnik_konwektorowy_stiebel_eltron_cns200trend.png" alt="" />
                            </div>
                            <div className='right'>
                                <p><strong>Grzejniki konwektorowe</strong></p>
                                
                                <p>Zalety</p>
                                <ul>
                                    <li>Mała masa i pojemność wodna</li>
                                    <li>Krótki czas nagrzewania</li>
                                    <li>Niewielkie rozmiary</li>
                                    <li>Efektywne działanie</li>
                                </ul>

                                <p>Wady</p>
                                <ul>
                                    <li>Sprzyjają unoszeniu się kurzu</li>
                                    <li>Wymagają czyszczenia, które jest kłopotliwe</li>
                                </ul>
                            </div>
                        </div>
                        <div className='item'>
                            <div className='left'>
                                <img src="https://dreamheat.eu/wp-content/uploads/2022/03/ppc-600x363.png" alt="" />
                            </div>
                            <div className='right'>
                                <p><strong>Pompy ciepła</strong></p>
                                
                                <p>Zalety</p>
                                <ul>
                                    <li>Odpowiednia instalacja sprawia, że urządzenie jest bezobsługowe</li>
                                    <li>Nie ma potrzeby ładowania opału, czyszczenia pieca i jego rozpalania</li>
                                    <li>Nie wytwarza spalin</li>
                                    <li>Łatwa do montażu</li>
                                </ul>

                                <p>Wady</p>
                                <ul>
                                    <li>Wysokie koszty zakupu i instalacji</li>
                                    <li>Zajmuje dużo miejsca</li>
                                    <li>Emituje pewien poziom hałasu, który może okazać się uciążliwy</li>
                                </ul>
                            </div>
                        </div>
                        <div className='item'>
                            <div className='left'>
                                <img src="https://dreamheat.eu/wp-content/uploads/2022/03/mata-600x363.png" alt="" />
                            </div>

                            <div className='right'>
                                <p><strong>Maty grzewcze z drutów oporowych</strong></p>
                                
                                <p>Zalety</p>
                                <ul>
                                    <li>Ciepło oddawane jest równomiernie w całym pomieszczeniu</li>
                                    <li>Ogrzewanie jest niewidoczne</li>
                                </ul>

                                <p>Wady</p>
                                <ul>
                                    <li>Powolne nagrzewanie się i powolne stygnięcie</li>
                                    <li>Wymaga zaplanowania aranżacji wnętrza przed instalacją przewodów</li>
                                    <li>Nie ma możliwości wykonania zmian w gotowej inwestycji, więc gdy dojdzie do awarii nie obędzie się bez skucia całej podłogi</li>
                                    <li>Możliwości wyboru materiałów wykończeniowych są bardzo ograniczone</li>
                                </ul>
                            </div>
                        </div>
                        <div className='item'>
                            <div className='left'>
                                <img src="https://dreamheat.eu/wp-content/uploads/2022/02/logo-test.png" alt="" />
                            </div>

                            <div className='right'>
                                <p><strong>Folia grzewcze Dream Heat</strong></p>
                                
                                <p>Zalety</p>
                                <ul>
                                    <li>Bardzo niskie koszty montażu i eksploatacji (brak pieca, rur, dodatkowego miejsca, materiałów itp.)</li>
                                    <li>Szybko ogrzewa pomieszczenie</li>
                                    <li>Dowolność manipulacji tym kiedy i na ile ma zostać włączone ogrzewanie</li>
                                    <li>Ogrzewa powierzchnie, następnie powietrze w pomieszczeniu, dzięki czemu temperatura powietrza jest stała, powietrze nie wiruje i nie unosi kurzu.</li>
                                    <li>Wszechstronne możliwości użycia:
                                        <ol>
                                            <li>można je zainstalować w podłodze, ścianach lub suficie</li>
                                            <li>znajdzie zastosowanie w domach, mieszkaniach, halach, szklarniach, obiektach gospodarczych, budynkach użytku publicznego</li>
                                            <li>mogą być zainstalowane pod wieloma różnymi materiałami takimi jak na przykład: pod drewnem, płytami, gipsem, kartonem, kaflami itd.</li>
                                        </ol>
                                    </li>
                                    <li>Łatwość samodzielnego wykonania instalacji</li>
                                    <li>Po montażu są niewidoczne, nie zajmują miejsca w pomieszczeniu</li>
                                    <li>Bezobsługowy i niebrudzący</li>
                                    <li>Nie wymaga komina i kotłowni</li>
                                    <li>Po połączeniu folii grzewczej Dream Heat z energią pochodzącą z instalacji fotowoltaicznej, użytkownik korzysta z bezpłatnej energii słonecznej, której koszt pozyskania jest darmowy i niezależny od wzrostów cen energii elektrycznej</li>
                                    <li>Ma korzystny wpływ na jakość powietrza</li>
                                    <li>Nie wydziela CO<sub>2</sub> do atmosfery</li>
                                    <li>Ogranicza korzystanie z nieodnawialnych źródeł energii</li>
                                    <li>Produkt posiada 30 lat gwarancji</li>
                                </ul>

                                <p>Wady</p>
                                <ul>
                                    <li>Nie ogrzewa wody użytkowej</li>
                                </ul>
                            </div>
                        </div>
                        <span className="clear"></span>
                    </div>
                </section>
            </div>
        );
    }
}