import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import Lightbox from 'react-lightbox-component';

import '../../../node_modules/react-lightbox-component/build/css/index.css';
import '../../styles/basic.scss';

export default class PrincipleOfOperation extends Component {
    constructor() {
        super();
    }

    render() {
        const images = [
            { src: require('../../gfx/wiedza/dom_raz_dwa_z_napisami.png'), width: 960, height: 679, description: 'Zasada działania instalacji fotowoltaicznej' }
        ];

        return (
            <div className='container'>
                <Helmet>
                    <meta name="description" content="Zasada działania instalacji fotowoltaicznej" />
                    <meta name="keywords" content="instalacja fotowoltaiczna" />
                    <link rel="canonical" href="https://raz-dwa.pl/fotowoltaika/zasada-dzialania" />
                    <title>Raz Dwa - Fotowoltaika - Zasada działania</title>
                </Helmet>

                <section className='article'>
                    <p className='h3'>Zasada działania instalacji fotowoltaicznej</p>
                    <Lightbox
                        images={images}
                        renderImageFunc={(idx, image, toggleLightbox) => {
                            return (
                                <img
                                    key={idx}
                                    src={image.src}
                                    width={image.width}
                                    height={image.height}
                                    alt={image.description}
                                    onClick={toggleLightbox.bind(null, idx)} />
                            );
                        }}
                    />
                    <p><strong>Jak działa instalacja fotowoltaiczna?</strong></p>
                    <p>Dzięki zjawisku fotowoltaicznemu zachodzącemu w ogniwach, z których zbudowany jest moduł fotowoltaiczny energia słoneczna jest zamieniana na energię elektryczną. Prąd stały bo taki właśnie wytwarza generator PV trafia do falownika. Falownik jest odpowiedzialny za zmianę prądu stałego na przemienny o parametrach dopasowanych do sieci energetycznej.</p>
                    <p><strong>Co dzieje się z niewykorzystaną energią?</strong></p>
                    <p>Licznik dwukierunkowy dokonuje pomiarów dwukierunkowego przepływu prądu tzn. zlicza energię elektryczną wyprodukowaną przez naszą instalację oraz pobraną z sieci energetycznej. Może się bowiem zdarzyć, iż energii wytworzonej przez naszą mikroelektrownię będzie za dużo lub za mało w stosunku do naszego zapotrzebowania. W pierwszym przypadku nadmiar energii zostaje przekazany do sieci energetycznej, a my możemy odebrać 80% wyprodukowanej przez nas energii. Jest to związane z systemem opustów dla prosumentów, czyli osób wytwarzających energię na użytek własny. Dla instalacji, których moc nie przekracza 10kWp obowiązuje stosunek 1:0,8 w ramach systemu opustu, co oznacza, że za 1kWh oddaną do sieci właściciel mikroelektrowni może odebrać 0,8 kWh. W przypadku instalacji o mocy między 10 a 50 kWp rozliczamy się w stosunku 1:0,7 - oddana przez nas 1 kWh pozwala nam na odbiór 0,7 kWh. W sytuacji, gdy istniejącego zapotrzebowania nie uda nam się całkowicie pokryć wykorzystując wcześniej wytworzoną i odebraną przez nas energię, pozostała różnica zostaje wyrównana energią z sieci w stosunku 1:1.</p>
                </section>
            </div>
        );
    }
}