import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

export default class Downloadable extends Component {
    constructor() {
        super();
    }

    render() {
        return (
            <div className='container'>
                <Helmet>
                    <meta name="description" content="Dokumenty do pobrania na temat inteligentnego ogrzewania" />
                    <meta name="keywords" content="dokumenty o inteligentnym ogrzewaniu" />
                    <link rel="canonical" href="https://raz-dwa.pl/ogrzewanie/do-pobrania" />
                    <title>Raz Dwa - Ogrzewanie - Do pobrania</title>
                </Helmet>

                <section className="downloadable">
                    <p className='h3'>Do pobrania</p>
                    <div className='item'>
                        <p className='title'>Cenniki</p>
                        <div className='files'>
                            <a href={require('../../files/Produkty_Dream_Heat_Cennik2022_web.pdf')}>Cennik Dream Heat</a>
                            <a href={require('../../files/Cennik_AMR_2022.pdf')}>Cennik AMR</a>
                            <a href={require('../../files/Cennik_Heat_Force.pdf')}>Cennik Promienników Heat Force</a>
                        </div>
                    </div>
                    <div className='item'>
                        <p className='title'>Katalogi</p>
                        <div className='files'>
                            <a href={require('../../files/Katalog2022.pdf')}>Katalog PL</a>
                            <a href={require('../../files/DHKatalogEN.pdf')}>Katalog EN</a>
                            <a href={require('../../files/DHKatalogDE.pdf')}>Katalog DE</a>
                        </div>
                    </div>
                    <div className='item'>
                        <p className='title'>Karty produktu</p>
                        <div className='files'>
                            <a href={require('../../files/DH-No.1.pdf')}>Folia DH-No.1</a>
                            <a href={require('../../files/DH-No.2.pdf')}>Folia DH-No.2</a>
                            <a href={require('../../files/DH-No.3.pdf')}>Folia DH-No.3</a>
                            <a href={require('../../files/DH-No.4.pdf')}>Folia DH-No.4</a>
                        </div>
                    </div>
                    <div className='item'>
                        <p className='title'>Reklamacje</p>
                        <div className='files'>
                            <a href={require('../../files/proces_reklamacyjny.pdf')}>Opis procesu reklamacyjnego</a>
                            <a href={require('../../files/formularz.pdf')}>Formularz reklamacyjny</a>
                        </div>
                    </div>
                    <div className='item'>
                        <p className='title'>Instrukcje</p>
                        <div className='files'>
                            <a href={require('../../files/nj-002-pl.pdf')}>Instrukcja obsługi termostatu NJ-002</a>
                            <a href={require('../../files/nj-003-pl.pdf')}>Instrukcja obsługi termostatu NJ-003</a>
                            <a href={require('../../files/NJ_6000_PL_web.pdf')}>Instrukcja obsługi termostatu NJ 6000</a>
                        </div>
                    </div>
                    <div className='item'>
                        <p className='title'>Inne</p>
                        <div className='files'>
                            <a href={require('../../files/wytyczne-dla-inwestora.pdf')}>Wytyczne dla inwestora</a>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}