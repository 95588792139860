import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

export default class Garden extends Component {
    constructor() {
        super();
    }

    render() {
        return (
            <div className='container'>
                <Helmet>
                    <meta name="description" content="Zastosowanie opcji inteligentnego domu w ogrodzie" />
                    <meta name="keywords" content="inteligentny dom w ogrodzie" />
                    <link rel="canonical" href="https://raz-dwa.pl/inteligentne-domy/funkcjonalnosci/ogrod" />
                    <title>Raz Dwa - Inteligentne domy - Ogród</title>
                </Helmet>

                <section>
                    <p className='h3'>Ogród</p>
                    <p>Opieka nad ogrodem</p>
                    <p>Zarządzaj kompleksowo zielenią wokół domu. Doglądaj ogród przez kamerę z poziomu aplikacji mobilnej. Stwórz harmonogram podlewania z obsługą czujnika wilgotności, temperatury i światła. Wygodnie zarządzaj oświetleniem ogrodowym bez wstawania z fotela. Z Ampio Smart Home zautomatyzujesz włączanie zewnętrznego oświetlenia, tworząc idealne scenerie.</p>

                    <div className='gridStack withoutButton'>
                        <div className='item'>
                            <div className='left'>
                                <img src='https://p2p6d7w4.rocketcdn.me/wp-content/uploads/2021/03/AdobeStock_204761579-1-1-scaled-e1632930728290.jpg' alt=''/>
                            </div>
                            <div className='right'>
                                <h2>Inteligentna irygacja</h2>
                                <p>Zadbaj o swój ogród i stwórz harmonogram nawadniania uzależniony od czynników zewnętrznych. Dzięki temu w przypadku dużej wilgotności Twoje zraszacze nie uruchomią się, zaś w razie bardzo wysokiej temperatury zadbają o odpowiednie nawilżenie Twojego trawnika i roślin.</p>
                            </div>
                        </div>
                        <div className='item reverse'>
                            <div className='left'>
                                <img src='https://p2p6d7w4.rocketcdn.me/wp-content/uploads/2021/03/Mask-Group-9.png' alt=''/>
                            </div>
                            <div className='right'>
                                <h2>Zmiana oświetlenia</h2>
                                <p>Twój ogród może wyglądać olśniewająco nie tylko w dzień. Oświetlenie ogrodowe samo włączy się i dostosuje do warunków pogodowych oraz pory dnia (lub nocy). W ten sposób oszczędzasz energię i sprawiasz, że ogród wygląda pięknie nawet po zmroku. Możesz wykorzystać do tego zegar astronomiczny lub czujnik jasności.</p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}