import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';

export default class HeatingSystems extends Component {
    constructor() {
        super();
    }

    render() {
        return (
            <div className='container'>
                <Helmet>
                    <meta name="robots" content="noindex, nofollow" />
                    <link rel="canonical" href="https://raz-dwa.pl/ogrzewanie" />
                    <title>Raz Dwa - Ogrzewanie</title>
                </Helmet>

                <section>
                    <Redirect to='/ogrzewanie/folie-grzewcze'/>
                </section>
            </div>
        );
    }
}