import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

export default class HowMuchItCosts extends Component {
    constructor() {
        super();
    }

    render() {
        return (
            <div className='container'>
                <Helmet>
                    <meta name="description" content="Koszta potrzebne do inwestycji w ogrzewanie" />
                    <meta name="keywords" content="ogrzewanie folią grzewczą" />
                    <link rel="canonical" href="https://raz-dwa.pl/ogrzewanie/strefa-wiedzy/ile-to-kosztuje" />
                    <title>Raz Dwa - Ogrzewanie - Strefa wiedzy - Ile to kosztuje</title>
                </Helmet>

                <section>
                    <p className='h3'>Ile kosztuje inwestycja w ogrzewanie</p>
                    <p>Koszt i czas realizacji inwestycji oraz koszty użytkowania na przykładzie 100m<sup>2</sup> domu jednorodzinnego</p>
                    <p>Ogrzewanie Dream Heat jest atrakcyjną alternatywą dla istniejących rozwiązań grzewczych ze względu na niskie koszty inwestycyjne oraz
                        możliwość obniżenia kosztów użytkowania do zera z fotowoltaiką. Średni koszt zużycia energii przy tradycyjnym zasilaniu z sieci wynosi
                        tylko około 2500zł za rok. Dodatkową korzyścią jest brak serwisów i kosztów eksploatacyjnych oraz 30 letnia gwarancja na folie grzewcze.
                        Dobrze skonfigurowany system jest praktycznie bezobsługowy i niewidoczny.</p>

                    <img src="https://dreamheat.eu/wp-content/uploads/2022/05/Obszar-roboczy-1tabelka-600x426.jpg" alt="" />

                    <p><strong>Na czym oszczędzamy korzystając z rozwiązań Dream Heat?</strong></p>
                    <ul>
                        <li><strong>Brak komina:</strong> koszt 7 - 8 tys.</li>
                        <li><strong>Brak kotła:</strong> koszt 5 - 8 tys.</li>
                        <li><strong>Brak przyłącza gazowego droga - działka:</strong> koszt 8 - 10 tys.</li>
                        <li><strong>Brak instalacji czujek gazu, czadu, dymu:</strong> koszt 2 - 3 tys.</li>
                        <li><strong>Brak kaloryferów:</strong> koszt 3 - 5 tys.</li>
                        <li><strong>Brak serwisu kotłowni dla okresu 15 lat:</strong> koszt 4 - 5 tys.</li>
                        <li><strong>Brak wizyt kominiarza dla okresu 15 lat:</strong> koszt 3 tys.</li>
                        <li><strong>Naprawy pogwarancyjne:</strong> koszt 2 - 10 tys. przez okres 15 lat</li>
                    </ul>

                    <img src="https://dreamheat.eu/wp-content/uploads/2022/05/EnergySaving-600x494.jpg" alt="" />

                    <p><strong>Co potrzebujemy?</strong></p>

                    <div className='fourStack'>
                        <div className='item'>
                            <img src="https://dreamheat.eu/wp-content/uploads/2022/03/dreamfolia3-640x517-1-400x323.png" alt="" />
                            <p><strong>Folia Dream Heat</strong></p>
                            <p>64m<sup>2</sup> x 195zł</p>
                        </div>
                        <div className='item'>
                            <img src="https://dreamheat.eu/wp-content/uploads/2022/03/1_0006_mata-400x323.jpg" alt="" />
                            <p><strong>Pianka izolacyjna</strong></p>
                            <p>120m<sup>2</sup> x 21,5zł</p>
                        </div>
                        <div className='item'>
                            <img src="https://dreamheat.eu/wp-content/uploads/2022/03/1_0005_folia-400x323.jpg" alt="" />
                            <p><strong>Folia budowlana</strong></p>
                            <p>1 x 173zł</p>
                        </div>
                        <div className='item'>
                            <img src="https://dreamheat.eu/wp-content/uploads/2022/03/1_0004_kabel-400x323.jpg" alt="" />
                            <p><strong>Przewód</strong></p>
                            <p>120m x 4,8zł</p>
                        </div>
                        <div className='item'>
                            <img src="https://dreamheat.eu/wp-content/uploads/2022/03/1_0003_zlaczki-400x323.jpg" alt="" />
                            <p><strong>Złączki</strong></p>
                            <p>100 x 1,8zł</p>
                        </div>
                        <div className='item'>
                            <img src="https://dreamheat.eu/wp-content/uploads/2022/03/1_0000_termo-400x323.jpg" alt="" />
                            <p><strong>Termostat</strong></p>
                            <p>7 x 405zł</p>
                        </div>
                        <div className='item'>
                            <img src="https://dreamheat.eu/wp-content/uploads/2022/03/1_0001_tasma-400x323.jpg" alt="" />
                            <p><strong>Taśma</strong></p>
                            <p>2 x 11zł</p>
                        </div>
                    </div>
                    <p><strong>Profesjonalny montaż</strong> wykonany przez naszego fachowca <strong>4800 zł</strong></p>
                </section>

                <section>
                    <div className='gridStack'>
                        <div className='item'>
                            <div className='left'>
                                <p><strong>To wszystko!</strong></p>
                                <p>Twój dom zyskał ciepło! za cenę <strong>23646 zł netto + VAT</strong></p>
                                <p>Instalacja DreamHeat nie wymaga serwisu, a po połączeniu z panelami fotowoltaicznymi ogrzewamy dom za <strong>0 zł</strong> rocznie!</p>
                            </div>
                            <div className='right'>
                                <img src="https://dreamheat.eu/wp-content/uploads/2022/03/rodzina2.png" alt="" />
                            </div>
                        </div>
                    </div>

                    <p>Powyższa kalkulacja ma charakter informacyjny i nie stanowi oferty handlowej w rozumieniu art. 66 §1 kodeksu cywilnego oraz innych właściwych przepisów prawnych.</p>
                </section>
            </div>
        );
    }
}