import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

export default class GatesAndShutters extends Component {
    constructor() {
        super();
    }

    render() {
        return (
            <div className='container'>
                <Helmet>
                    <meta name="description" content="Zastosowanie opcji inteligentnego domu dla bramy i rolet" />
                    <meta name="keywords" content="inteligentny dom do bram i rolet" />
                    <link rel="canonical" href="https://raz-dwa.pl/inteligentne-domy/funkcjonalnosci/bramy-i-rolety" />
                    <title>Raz Dwa - Inteligentne domy - Bramy i rolety</title>
                </Helmet>

                <section>
                    <p className='h3'>Bramy i rolety</p>
                    <p>Sterowanie roletami i bramami</p>
                    <p>Inteligentna obsługa mechanizmów bram, drzwi i rolet. Skorzystaj z możliwości sterowania zdalnie, bez wysiadania z auta lub automatycznie według stworzonego przez Ciebie planu. Miej wszystko pod kontrolą - niezależnie gdzie jesteś</p>

                    <div className='gridStack withoutButton'>
                        <div className='item'>
                            <div className='left'>
                                <img src='https://p2p6d7w4.rocketcdn.me/wp-content/uploads/2021/03/warunki_atmosferyczne-e1616680523920.png' alt=''/>
                            </div>
                            <div className='right'>
                                <h2>Inteligentne rolety</h2>
                                <p>Zostawiłeś uchylone okna w domu i martwisz się o nadchodzącą burzę? Za pomocą systemu Ampio Smart Home możesz zdalnie sterować roletami, żaluzjami i markizami. Aby było jeszcze łatwiej, sterowniki i moduły wchodzące w skład systemu same zareagują na zmianę warunków atmosferycznych temperatury. Zimą rolety podniosą się, by dogrzać dom, wpuszczając promienie słońca a latem zostaną zasunięte, aby nie nagrzewać wnętrza. Takie rozwiązanie to nie tylko wygoda, ale także mniejsze rachunki za ogrzewanie i klimatyzację.</p>
                            </div>
                        </div>
                        <div className='item reverse'>
                            <div className='left'>
                                <img src='https://p2p6d7w4.rocketcdn.me/wp-content/uploads/2021/03/uzbrajanie_telefon.png' alt=''/>
                            </div>
                            <div className='right'>
                                <h2>Gotowy do wyjazdu?</h2>
                                <p>Gdy myślami jesteś już na urlopie, umknąć mogą CI nawet te najważniejsze kwestie. Z Ampio nie musisz martwić się o to, czy zamknąłeś drzwi oraz czy włączyłeś alarm - nasz system pamięta o tym sam. Lubisz mieć więcej niż 100% pewności? Możesz w każdej chwili sprawdzić w smartfonie, czy alarm jest uzbrojony, światło zgaszone a rolety opuszczone.</p>
                            </div>
                        </div>
                        <div className='item'>
                            <div className='left'>
                                <img src='https://p2p6d7w4.rocketcdn.me/wp-content/uploads/2021/03/AdobeStock_191303286.png' alt=''/>
                            </div>
                            <div className='right'>
                                <h2>Optymalne warunki</h2>
                                <p>Lubisz, gdy budzi Cię poranne promienie słońca? Ustaw rolety tak, aby unosiły się o świcie. Nasz system będzie pamiętał również o dostosowaniu temperatury wewnątrz domu - to funkcja przydatna zwłaszcza w lecie, gdy rano szybko robi się ciepło.</p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}