import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

export default class HeatingFoils extends Component {
    constructor() {
        super();
    }

    render() {
        return (
            <div className='container'>
                <Helmet>
                    <meta name="description" content="Rodzaje folii grzewczych do ogrzewania ścian oraz sufitów" />
                    <meta name="keywords" content="folie grzewcze" />
                    <link rel="canonical" href="https://raz-dwa.pl/ogrzewanie/folie/folie-grzewcze" />
                    <title>Raz Dwa - Ogrzewanie - Folie grzewcze</title>
                </Helmet>

                <section>
                    <p className='h3'>Folie grzewcze</p>
                    <p>Folia Dream Heat służy do ogrzewania ścian, podłóg oraz sufitów, które następnie ogrzewają powietrze w pomieszczeniu. Dzięki temu, temperatura powietrza jest stała.</p>
                    <p><strong>Materiał</strong> - folię Dream Heat tworzy mata grzewcza utworzona z połączenia włókna węglowego z pastą z karbonu. Mata pokryta jest dodatkowymi dwiema warstwami laminatu, tworząc łącznie 11 warstw, czyniąc produkt bardzo cienkim (tylko 0,7 mm) w porównaniu z klasycznym ogrzewaniem podłogowym.</p>
                    <p><strong>Technologia</strong> - zasadą działania folii Dream Heat jest ogrzewanie podczerwienią – INFRA RED*.</p>
                </section>

                <section className="heatingFoil">
                    <p>Rodzaje mat grzewczych podłogowych:</p>
                    <ul>
                        <li><strong>Napięcie:</strong> 230V</li>
                        <li><strong>Zewnętrzny czujnik temp.:</strong> 0 - 35°C</li>
                    </ul>
                    <a href={require('../../files/karta_1.pdf')} className='button'>Karta katalogowa</a>
                    <div className='twoStack'>
                        <div className='item'>
                            <p>DH N°1</p>
                            <img src={require('../../gfx/ogrzewanie/folie/dreamfolia3-640x517-1-400x323.png')} alt="Folia Dream Heat DH N°1"/>
                            <ul>
                                <li><strong>Maksymalna moc na m<sup>2</sup>:</strong> 240W</li>
                                <li><strong>Tolerancja mocy:</strong> 240W +-10%</li>
                                <li><strong>Minimalny rozmiar ciętej folii:</strong> 50X20(cm)</li>
                                <li><strong>Maksymalna temp. grzania:</strong> 50°C</li>
                                <li><strong>Zakres temp.:</strong> 0-50°C</li>
                                <li><strong>Sprawność:</strong> 99% +-1%</li>
                                <li><strong>Waga produktu:</strong> 41kg</li>
                            </ul>
                        </div>
                        <div className='item'>
                            <p>DH N°2</p>
                            <img src={require('../../gfx/ogrzewanie/folie/dh2folia-640x517-1-400x323.png')} alt="Folia Dream Heat DH N°2"/>
                            <ul>
                                <li><strong>Maksymalna moc na m<sup>2</sup>:</strong> 400W</li>
                                <li><strong>Tolerancja mocy:</strong> 400W +-10%</li>
                                <li><strong>Minimalny rozmiar ciętej folii:</strong> 33X10(cm)</li>
                                <li><strong>Maksymalna temp. grzania:</strong> 50°C</li>
                                <li><strong>Zakres temp.:</strong> 0 - 50°C</li>
                                <li><strong>Sprawność:</strong> 99% +-1%</li>
                                <li><strong>Waga produktu:</strong> 38kg</li>
                            </ul>
                        </div>
                        <div className='item'>
                            <p>DH N°3</p>
                            <img src={require('../../gfx/ogrzewanie/folie/dreamfolia3-640x517-1-400x323.png')} alt="Folia Dream Heat DH N°3"/>
                            <ul>
                                <li><strong>Maksymalna moc na m<sup>2</sup>:</strong> 220W</li>
                                <li><strong>Tolerancja mocy:</strong> 220W +-10%</li>
                                <li><strong>Minimalny rozmiar ciętej folii:</strong> 33X10(cm)</li>
                                <li><strong>Maksymalna temp. grzania:</strong> 50°C</li>
                                <li><strong>Zakres temp.:</strong> 0 - 50°C</li>
                                <li><strong>Sprawność:</strong> 99% +-1%</li>
                                <li><strong>Waga produktu:</strong> 38kg</li>
                            </ul>
                        </div>
                        <div className='item'>
                            <p>DH N°4</p>
                            <img src={require('../../gfx/ogrzewanie/folie/dreamfolia3-640x517-1-400x323.png')} alt="Folia Dream Heat DH N°4"/>
                            <ul>
                                <li><strong>Maksymalna moc na m<sup>2</sup>:</strong> 160W</li>
                                <li><strong>Tolerancja mocy:</strong> 240W +-10%</li>
                                <li><strong>Minimalny rozmiar ciętej folii:</strong> 50X20(cm)</li>
                                <li><strong>Maksymalna temp. grzania:</strong> 40°C</li>
                                <li><strong>Zakres temp.:</strong> 0 - 40°C</li>
                                <li><strong>Sprawność:</strong> 99% +-1%</li>
                                <li><strong>Waga produktu:</strong> 41kg</li>
                            </ul>
                        </div>
                    </div>
                    <span className="clear"></span>
                </section>

                <section>
                    <div className='gridStack video'>
                        <div className='item'>
                            <div className='left'>
                                <iframe width="465" height="262" src="https://www.youtube.com/embed/ffNmzrVcvnc" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                            </div>
                            <div className='right'>
                                <p><strong>Zobacz film z montażu</strong></p>
                                <p>Istotnym walorem folii Dream Heat jest łatwość samodzielnego wykonania instalacji przez inwestora, która zakłada, że dla domu o powierzchni 150 m<sup>2</sup> montaż folii Dream Heat zajmuje 1 dzień roboczy, Folia dostarczana jest w rolkach o dowolnej długości i szerokości 100 cm, z możliwością docinania, co 25 cm co jest jej dodatkowym atutem.</p>
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <div className='threeStack'>
                        <div className='item'>
                            <img src={require('../../gfx/ogrzewanie/folie/sufit.png')} alt="" />
                            <p><strong>Montaż na suficie</strong></p>
                            <ul>
                                <li>Strop</li>
                                <li>Stelaż i wełna mineralna</li>
                                <li>Ekran aluminiowy</li>
                                <li>Folia Dream Heat</li>
                                <li>Płyta gips karton</li>
                            </ul>
                        </div>
                        <div className='item'>
                            <img src={require('../../gfx/ogrzewanie/folie/panele.png')} alt="" />
                            <p><strong>Montaż na podłodze z panelami</strong></p>
                            <ul>
                                <li>Podłoga drewniana</li>
                                <li>Folia PE</li>
                                <li>Folia Dream Heat</li>
                                <li>Ekran aluminiowy</li>
                                <li>Podłoże</li>
                            </ul>
                        </div>
                        <div className='item'>
                            <img src={require('../../gfx/ogrzewanie/folie/sufit.png')} alt="" />
                            <p><strong>Montaż na podłodze z kaflami</strong></p>
                            <ul>
                                <li>Kafle podłogowe</li>
                                <li>Klej i wylewka</li>
                                <li>Folia pefolia Dream Heat</li>
                                <li>Ekran aluminiowy</li>
                                <li>Podłoże</li>
                            </ul>
                        </div>
                    </div>
                    <span className="clear"></span>

                    <p>Folie Dream Heat można instalować w podłodze, ścianach lub na suficie. W budynkach mieszkalnych i gospodarczych.</p>
                    <p>Dokładne specyfikacje i instrukcje montażu folii grzewczych dostępne są u naszego doradcy technicznego.</p>
                </section>

                <section>
                    <p>Poprzez zastosowanie laminatów odpornych na termokurczliwość, produkt posiada 30-sto letnią gwarancję.</p>
                    <p>Ogrzewanie podłogowe Dream Heat to XXI wiek w technice grzewczej.</p>
                    <ul>
                        <li>W pełni zintegrowany system ogrzewania Dream Sun, zakłada połączenie folii grzewczej Dream Heat z energią pochodzącą z instalacji fotowoltaicznej</li>
                        <li>Koszt paneli fotowoltaicznych można porównać do kosztu budowy kotłowni, co w rezultacie daje użytkownikowi całkowicie darmowe i bezobsługowe ogrzewanie domu</li>
                        <li>Cały proces jest wspierany przez inteligentne rozwiązania - Dream Connect Wi-Fi</li>
                        <li>Bazując na energii słonecznej, system ogrzewania Dream Sun idealnie wpisuje się w strategię zrównoważonego rozwoju oraz odnawialnych źródeł energii</li>
                    </ul>
                </section>
            </div>
        );
    }
}