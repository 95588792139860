import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

export default class Fuels extends Component {
    constructor() {
        super();
    }

    render() {
        return (
            <div className='container'>
                <Helmet>
                    <meta name="description" content="Porównanie rodzajów paliw" />
                    <meta name="keywords" content="porównanie paliw metod ogrzewania" />
                    <link rel="canonical" href="https://raz-dwa.pl/ogrzewanie/strefa-wiedzy/paliwa" />
                    <title>Raz Dwa - Ogrzewanie - Strefa wiedzy - Paliwa</title>
                </Helmet>

                <section>
                    <p className='h3'>Rodzaje paliw</p>
                    <div className='twoStack'>
                        <div className='item'>
                            <div className="left">
                                <img src="https://dreamheat.eu/wp-content/uploads/2022/03/gaz-600x363.png" alt="" />
                            </div>
                            <div className="right">
                                <p><strong>Gaz ziemny</strong></p>
                                <p>Zalety</p>
                                <ul>
                                    <li>Bezobsługowość pieca</li>
                                    <li>Olej opałowy to paliwo powszechnie dostępne, szeroka sieć dystrybutorów szybko i sprawnie realizujący zamówienia</li>
                                    <li>Ekologiczne paliwo, kotły olejowe są przyjazne środowisku</li>
                                    <li>Wysoka temperatura zapłonu (min. 55°C) minimalizuje niebezpieczeństwo nagłego wybuchu spowodowanego przypadkowym zapłonem.</li>
                                </ul>
                                <p>Wady</p>
                                <ul>
                                    <li>Olej opałowy objęty jest akcyzą, jego cena jest bardzo wysoka, jego ceny podlegają znacznym wahaniom</li>
                                    <li>Ogrzewanie olejowe wymaga także wyznaczenia w domu miejsca na kotłownię, która musi spełnić wiele konkretnych parametrów</li>
                                    <li>Miejsce, w którym przechowujemy olej powinno mieć odpowiednią powierzchnię i wysokość</li>
                                    <li>Olej nie może być wykorzystywany do podgrzewania wody.</li>
                                    <li>Konieczność kontrolowania na bieżąco poziomu paliwa, żeby wiedzieć, jak umawiać się z dostawcami.</li>
                                </ul>
                            </div>
                        </div>

                        <div className='item'>
                            <div className="left">
                                <img src="https://dreamheat.eu/wp-content/uploads/2022/03/olejopalowy-600x363.png" alt="" />
                            </div>
                            <div className="right">
                                <p><strong>Olej opałowy</strong></p>
                                <p>Zalety</p>
                                <ul>
                                    <li>Bezobsługowość pieca</li>
                                    <li>Olej opałowy to paliwo powszechnie dostępne, szeroka sieć dystrybutorów szybko i sprawnie realizujący zamówienia</li>
                                    <li>Ekologiczne paliwo, kotły olejowe są przyjazne środowisku</li>
                                    <li>Wysoka temperatura zapłonu (min. 55°C) minimalizuje niebezpieczeństwo nagłego wybuchu spowodowanego przypadkowym zapłonem.</li>
                                </ul>
                                <p>Wady</p>
                                <ul>
                                    <li>Olej opałowy objęty jest akcyzą, jego cena jest bardzo wysoka, jego ceny podlegają znacznym wahaniom</li>
                                    <li>Ogrzewanie olejowe wymaga także wyznaczenia w domu miejsca na kotłownię, która musi spełnić wiele konkretnych parametrów</li>
                                    <li>Miejsce, w którym przechowujemy olej powinno mieć odpowiednią powierzchnię i wysokość</li>
                                    <li>Olej nie może być wykorzystywany do podgrzewania wody.</li>
                                    <li>Konieczność kontrolowania na bieżąco poziomu paliwa, żeby wiedzieć, jak umawiać się z dostawcami.</li>
                                </ul>
                            </div>
                        </div>

                        <div className='item'>
                            <div className="left">
                                <img src="https://dreamheat.eu/wp-content/uploads/2022/03/lpg-600x363.png" alt="" />
                            </div>
                            <div className="right">
                                <p><strong>Gaz LPG</strong></p>
                                <p>Zalety</p>
                                <ul>
                                    <li>Korzystne rozwiązanie tam, gdzie nie ma dostępu do sieci gazowej</li>
                                    <li>Wysoka sprawność kondensacyjnych kotłów gazowych</li>
                                    <li>Czystość (nie ma popiołu i pyłu węglowego)</li>
                                </ul>
                                <p>Wady</p>
                                <ul>
                                    <li>Dodatkowe formalności – trzeba spełnić konkretne warunki techniczne na działce i podczas instalacji</li>
                                    <li>Zbiornik naziemny na gaz zajmuje sporo miejsca</li>
                                    <li>Miejsce, w którym przechowujemy olej powinno mieć odpowiednią powierzchnię i wysokość</li>
                                    <li>Cena wykonania instalacji jest wyższa niż w przypadku alternatywnych źródeł ciepła</li>
                                </ul>
                            </div>
                        </div>

                        <div className='item'>
                            <div className="left">
                                <img src="https://dreamheat.eu/wp-content/uploads/2022/03/wegiel-600x363.png" alt="" />
                            </div>
                            <div className="right">
                                <p><strong>Węgiel kamienny</strong></p>
                                <p>Zalety</p>
                                <ul>
                                    <li>Opał jest łatwo dostępny</li>
                                    <li>To najtańsze ogrzewanie domu i nietrudno o sprawdzonego dostawcę</li>
                                    <li>Stosunkowo niskie koszty instalacji i ewentualnej naprawy kotła</li>
                                </ul>
                                <p>Wady</p>
                                <ul>
                                    <li>Ogrzewanie jest czasochłonne – wymaga regularnego dosypywania opału</li>
                                    <li>Po spalaniu pozostaje popiół, który należy usunąć</li>
                                    <li>Szkodliwy dla środowiska – wysoka emisja CO<sub>2</sub></li>
                                    <li>Wymaga miejsca na składowanie opału</li>
                                    <li>Nie da się utrzymać stałej, pokojowej temperatury w domu pod nieobecność mieszkańców – kocioł wymaga stałej obsługi</li>
                                </ul>
                            </div>
                        </div>

                        <div className='item'>
                            <div className="left">
                                <img src="https://dreamheat.eu/wp-content/uploads/2022/03/drewno.png" alt="" />
                            </div>
                            <div className="right">
                                <p><strong>Drewno opałowe</strong></p>
                                <p>Zalety</p>
                                <ul>
                                    <li>Koszt ogrzewania domu samym drewnem opałowym jest niski</li>
                                    <li>Drewno jest surowcem odnawialnym, a więc ekologicznym</li>
                                </ul>
                                <p>Wady</p>
                                <ul>
                                    <li>Drewno trzeba kupić, porąbać na kawałki, sezonować (mokre drewno jest znacznie mniej kaloryczne), wrzucać na bieżąco do kotła lub kominka</li>
                                </ul>
                            </div>
                        </div>

                        <div className='item'>
                            <div className='left'>
                                <img src="https://dreamheat.eu/wp-content/uploads/2022/02/logo-test.png" alt="" />
                            </div>

                            <div className='right'>
                                <p><strong>Folia grzewcze Dream Heat</strong></p>
                                <p>Zalety</p>
                                <ul>
                                    <li>Bardzo niskie koszty montażu i eksploatacji (brak pieca, rur, dodatkowego miejsca, materiałów itp.)</li>
                                    <li>Szybko ogrzewa pomieszczenie</li>
                                    <li>Dowolność manipulacji tym kiedy i na ile ma zostać włączone ogrzewanie</li>
                                    <li>Ogrzewa powierzchnie, następnie powietrze w pomieszczeniu, dzięki czemu temperatura powietrza jest stała, powietrze nie wiruje i nie unosi kurzu</li>
                                    <li>Wszechstronne możliwości użycia:
                                        <ol>
                                            <li>można je zainstalować w podłodze, ścianach lub suficie</li>
                                            <li>znajdzie zastosowanie w domach, mieszkaniach, halach, szklarniach, obiektach gospodarczych, budynkach użytku publicznego</li>
                                            <li>mogą być zainstalowane pod wieloma różnymi materiałami takimi jak na przykład: pod drewnem, płytami, gipsem, kartonem, kaflami itd.</li>
                                        </ol>
                                    </li>
                                    <li>Łatwość samodzielnego wykonania instalacji</li>
                                    <li>Po montażu są niewidoczne, nie zajmują miejsca w pomieszczeniu</li>
                                    <li>Bezobsługowy i niebrudzący</li>
                                    <li>Nie wymaga komina i kotłowni</li>
                                    <li>Po połączeniu folii grzewczej Dream Heat z energią pochodzącą z instalacji fotowoltaicznej, użytkownik korzysta z bezpłatnej energii słonecznej, której koszt pozyskania jest darmowy i niezależny od wzrostów cen energii elektrycznej</li>
                                    <li>Ma korzystny wpływ na jakość powietrza</li>
                                    <li>Nie wydziela CO<sub>2</sub> do atmosfery</li>
                                    <li>Ogranicza korzystanie z nieodnawialnych źródeł energii</li>
                                    <li>Produkt posiada 30 lat gwarancji</li>
                                </ul>
                                <p>Wady</p>
                                <ul>
                                    <li>Nie ogrzewa wody użytkowej</li>
                                </ul>
                            </div>
                        </div>
                        <span className="clear"></span>
                    </div>
                </section>
            </div>
        );
    }
}