import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';

export default class SmartHome extends Component {
    constructor() {
        super();
    }

    render() {
        return (
            <div className='container'>
                <Helmet>
                    <meta name="robots" content="noindex, nofollow" />
                    <link rel="canonical" href="https://raz-dwa.pl/inteligentne-domy" />
                    <title>Raz Dwa - Inteligentne domy</title>
                </Helmet>

                <section>
                    <Redirect to='/inteligentne-domy/funkcjonalnosci'/>
                </section>
            </div>
        );
    }
}