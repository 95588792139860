import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

export default class Lighting extends Component {
    constructor() {
        super();
    }

    render() {
        return (
            <div className='container'>
                <Helmet>
                    <meta name="description" content="Zarządzanie oświetleniem w domu" />
                    <link rel="canonical" href="https://raz-dwa.pl/inteligentne-domy/funkcjonalnosci/oswietlenie" />
                    <meta name="keywords" content="inteligentny dom, oświetlenie" />
                    <title>Raz Dwa - Inteligentne domy - Oświetlenie</title>
                </Helmet>

                <section>
                    <p className='h3'>Oświetlenie</p>
                    <p><strong>Inteligentne oświetlenie dopasowane do Ciebie</strong></p>
                    <p>Inteligentne oświetlenie domowe Ampio umożliwi Ci stworzenie optymalnego tła dla Twojej codziennej aktywności.</p>
                    <p>Nasze sterowniki i moduły tworzą system, który automatycznie oświetli drogę do kuchni, zgasi po Tobie światło, stworzy świetlną scenerię optymalną dla seansów filmowych, spotkań ze znajomymi lub będzie imitował Twoją obecność w domu.</p>
                    <p>Dzięki naszej aplikacji zainstalowanej na smartfonie każdy włącznik światła będzie w Twoim zasięgu. Niezależnie, czy będziesz aktualnie w domu, czy poza nim.</p>

                    <div className='gridStack withoutButton'>
                        <div className='item'>
                            <div className='left'>
                                <img src='https://p2p6d7w4.rocketcdn.me/wp-content/uploads/2021/03/AdobeStock_72036036-1-1.png' alt=''/>
                            </div>
                            <div className='right'>
                                <h2>Zadbaj o bezpieczeństwo</h2>
                                <p>System Ampio rejestruje uruchomienie każdego domowego urządzenia. Dzięki temu zna Twoje zwyczaje i potrafi je odtworzyć w trybie symulacji obecności. Możesz ją uruchomić w dowolnym momencie za pośrednictwem aplikacji. I tym samym zwiększyć bezpieczeństwo Twojego domu.</p>
                            </div>
                        </div>
                        <div className='item reverse'>
                            <div className='left'>
                                <img src='https://p2p6d7w4.rocketcdn.me/wp-content/uploads/2021/03/AdobeStock_235493605.png' alt=''/>
                            </div>
                            <div className='right'>
                                <h2>Bez zbędnych rachunków</h2>
                                <p>W Twoim domu znajdują się pomieszczenia, w których bywasz rzadko? W nocy nie chcesz po omacku szukać włącznika? Rozwiązaniem jest oświetlenie sterowane czujnikami ruchu. Dzięki nim nie musisz pamiętać o włączaniu i wyłączaniu światła - oraz martwić się o zbędne wydatki.</p>
                            </div>
                        </div>
                        <div className='item'>
                            <div className='left'>
                                <img src='https://p2p6d7w4.rocketcdn.me/wp-content/uploads/2021/03/AdobeStock_261727613-1.png' alt=''/>
                            </div>
                            <div className='right'>
                                <h2>Sceneria na każdą okazję</h2>
                                <p>Wieczór ze znajomymi, randka a może rodzinny obiad? Wykorzystaj pełnię możliwości sterowania oświetleniem i stwórz sceny świetlne odpowiednie dla każdej sytuacji. Zmieniaj ustawienia wielu źródeł światła jednocześnie dzięki naszej aplikacji lub panelom naściennym. Wystarczy jedno dotknięcie, aby w domowych wnętrzach stworzyć klimat taki, jaki chcesz.</p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}