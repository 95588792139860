import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

import '../../styles/basic.scss';

export default class Financing extends Component {
    constructor() {
        super();
    }

    render() {
        return (
            <div className='container'>
                <Helmet>
                    <meta name="description" content="Możliwości finansowania instalacji fotowoltaicznej" />
                    <meta name="keywords" content="finansowanie instalacji fotowoltaicznej" />
                    <link rel="canonical" href="https://raz-dwa.pl/fotowoltaika/finansowanie"/>
                    <title>Raz Dwa - Fotowoltaika - Finansowanie</title>
                </Helmet>

                <section className='article'>
                    <p className='h3'>Finansowanie</p>
                    <p className='desc'>Kredyt na zakup i montaż instalacji OZE</p>
                    <p>Osoby, które nie chcą inwestować własnych środków na zakup instalacji fotowoltaicznej oraz takie, które tych środków nie posiadają, mogą skorzystać z kredytu ratalnego dla osób fizycznych. Dzięki Alior Bank kredyt na instalację fotowoltaiczną uzyskasz bez wychodzenia z domu a ratę dopasujesz do wysokości obecnego rachunku za prąd dzięki czemu nie odczujesz jej ciężaru. Wszystkie formalności są zredukowane do minimum co pozwala w ciągu kilkunastu minut uzyskać decyzję kredytową przy jednoczesnym zachowaniu wysokich standardów bezpieczeństwa.</p>
                    <ul>
                        <li>niskie oprocentowanie</li>
                        <li>okres kredytowania do 10 lat</li>
                        <li>kwota kredytu brutto do 80 000 zł</li>
                        <li>zwrot odsetek za niewykorzystany okres</li>
                        <li>nie jest wymagana wpłata własna</li>
                    </ul>
                </section>
            </div>
        );
    }
}