import React, { Component } from 'react';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Lightbox from 'react-lightbox-component';

import '../../node_modules/react-lightbox-component/build/css/index.css';

export default class Home extends Component {
    constructor() {
        super();
    }

    componentDidMount() {
        document.body.className = 'home';
    }
    
    componentWillUnmount() {
        document.body.className = '';
    }

    render() {
        const settingsPartners = {
            autoplay: true,
            arrows: false,
            infinite: true,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 2,

            responsive: [
                {
                    breakpoint: 959,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        const settingsBanner = {
            autoplay: true,
            arrows: false,
            infinite: true,
            adaptiveHeight: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
        };

        const images = [
            { src: require('../gfx/certyfikaty/ibk.jpg'), width: 250, height: 353, description: 'Certyfikat Instytutu Badań Konsumenckich' },
            { src: require('../gfx/certyfikaty/goodwe.jpg'), width: 250, height: 353, description: 'Certyfikat Goodwe Plus+' },
            { src: require('../gfx/certyfikaty/dreamheat.jpg'), width: 250, height: 353, description: 'Certyfikat Dream Heat' },
            { src: require('../gfx/certyfikaty/imilm.jpg'), width: 250, height: 353, description: 'Certyfikat z Insytutu Metalurgii i Inżynierii Materiałowej im. Aleksandra Krupkowskiego Polskiej Akademii Nauk' },
            { src: require('../gfx/certyfikaty/kgifoxkrak.jpg'), width: 250, height: 353, description: 'Certyfikat z KGI FoxKrak' },
            { src: require('../gfx/certyfikaty/kgifoxkrak2.jpg'), width: 250, height: 353, description: 'Certyfikat z KGI FoxKrak' },
            { src: require('../gfx/certyfikaty/ampio.jpg'), width: 250, height: 177, description: 'Certyfikat Ampio' },
            { src: require('../gfx/certyfikaty/solaredge_podstawy.jpg'), width: 250, height: 177, description: 'SolarEdge - Trening podstaw' },
            { src: require('../gfx/certyfikaty/solaredge_zaawansowany.jpg'), width: 250, height: 177, description: 'SolarEdge - Trening zaawansowany' },
            { src: require('../gfx/certyfikaty/solaredge_praktyka.jpg'), width: 250, height: 177, description: 'SolarEdge - Trening praktyczny' }
        ];

        return (
            <div>
                <Helmet>
                    <meta name="description" content="Zainwestuj w odnawialne źródła energii. Zacznij od dziś - dopasowując ofertę do swoich potrzeb." />
                    <meta name="keywords" content="fotowoltaika, panele fotowoltaiczne, instalacja fotowoltaiczna" />
                    <link rel="canonical" href="https://raz-dwa.pl/" />
                    <title>Raz Dwa - Zainwestuj w zieloną energię!</title>
                </Helmet>

                <section id='slideshow'>
                    <Slider {...settingsBanner}>
                        <figure>
                            <figcaption>
                                <h1>Zainwestuj w odnawialne<br/>źródła energii</h1>
                            </figcaption>
                            <picture>
                                <source media="(max-width: 640px)" srcSet={require('../gfx/baner/photovoltaic-640.jpg')} type="image/jpg" />
                                <source media="(min-width: 641px) and (max-width: 1280px)" srcSet={require('../gfx/baner/photovoltaic-1280.jpg')} type="image/jpg" />
                                <img src={require('../gfx/baner/photovoltaic-1920.jpg')} alt='Zainwestuj w odnawialne źródła energii' width='1920' height='1262' />
                            </picture>
                        </figure>
                        <figure>
                            <figcaption>
                                <h2>Inteligentne zarządzanie<br/>domem</h2>
                            </figcaption>
                            <picture>
                                <source media="(max-width: 640px)" srcSet={require('../gfx/baner/smart-home-640.jpg')} type="image/jpg" />
                                <source media="(min-width: 641px) and (max-width: 1280px)" srcSet={require('../gfx/baner/smart-home-1280.jpg')} type="image/jpg" />
                                <img src={require('../gfx/baner/smart-home-1920.jpg')} alt='Inteligentne zarządzanie domem' width='1920' height='1279' />
                            </picture>
                        </figure>
                        <figure>
                            <figcaption>
                                <h3>Ogrzewanie</h3>
                            </figcaption>
                            <picture>
                                <source media="(max-width: 640px)" srcSet={require('../gfx/baner/heating-systems-640.jpg')} type="image/jpg" />
                                <source media="(max-width: 1280px)" srcSet={require('../gfx/baner/heating-systems-1280.jpg')} type="image/jpg" />
                                <img src={require('../gfx/baner/heating-systems-1920.jpg')} alt='Ogrzewanie' width='1920' height='1279' />
                            </picture>
                        </figure>
                    </Slider>
                </section>

                <section className='gridStack'>
                    <div className='container'>
                        <p className='h3'>Oferta</p>
                        <div className='item reverse'>
                            <div className='left'>
                                <img src={require('../gfx/oferta/4.jpg?v=1')} alt='Instalacje elektryczne' width='465' height='349' />
                            </div>
                            <div className='right'>
                                <h3><Link to='/instalacje-elektryczne'>Instalacje elektryczne</Link></h3>
                                <p>Profesjonalne usługi związane z wykonaniem i pomiarami instalacji elektrycznych oraz montażem automatyki bramowej.</p>
                                <button>Więcej</button>
                            </div>
                        </div>
                        <div className='item'>
                            <div className='left'>
                                <img src={require('../gfx/oferta/1.jpg?v=1')} alt='Fotowoltaika' width='465' height='349' />
                            </div>
                            <div className='right'>
                                <h3><Link to='/fotowoltaika'>Fotowoltaika</Link></h3>
                                <p>Instalacje fotowoltaiczne to nowoczesne źródła zielonej energii, które obniży Twoje rachunki za prąd. Uzyskaj niezależność energetyczną i nie martw się już więcej podwyżkami za prąd.</p>
                                <button>Więcej</button>
                            </div>
                        </div>
                        <div className='item reverse'>
                            <div className='left'>
                                <img src={require('../gfx/oferta/2.jpg?v=1')} alt='Inteligentne zarządzanie domem' width='465' height='349' />
                            </div>
                            <div className='right'>
                                <h3><Link to='/inteligentne-domy'>Inteligentne domy</Link></h3>
                                <p>Ampio Smart Home może sterować wszystkimi urządzeniami i systemami w Twoim domu. Stwórz swój wymarzony inteligentny dom, który będzie pasował do Twoich potrzeb.</p>
                                <button>Więcej</button>
                            </div>
                        </div>
                        <div className='item'>
                            <div className='left'>
                                <img src={require('../gfx/oferta/3.jpg?v=1')} alt='Ogrzewanie' width='465' height='349' />
                            </div>
                            <div className='right'>
                                <h3><Link to='/ogrzewanie'>Ogrzewanie</Link></h3>
                                <p>Postaw na rewolucyjne rozwiązania grzewcze. Bez konieczności ponoszenia większych wydatków możesz uzyskać wyższą temperaturę odczuwalną. To bardzo zdrowe i oszczędne ogrzewanie. Dołącz do grona zadowolonych klientów!</p>
                                <button>Więcej</button>
                            </div>
                        </div>
                    </div>
                </section>

                <section className='gallery'>
                    <div className='container'>
                        <p className='h3'>Certyfikaty</p>
                        <p><strong>Grzegorz Świerblewski</strong> założyciel firmy <strong>Raz Dwa</strong> należy do grona 4128 (dane na dzień 25.05.2021) certyfikowanych instalatorów systemów fotowoltaicznych w Polsce. Jednostką certyfikującą jest <a href='https://www.udt.gov.pl/' target='_blank' rel='noopener noreferrer'>Urząd Dozoru Technicznego</a>, a warunkiem przystąpienia do egzaminu jest ukończenie kursu teoretycznego i praktycznego w akredytowanym ośrodku szkoleniowym. Wybierając instalatora z ważnym <a href='https://www.udt.gov.pl/certyfikacja-instalatorow-oze/rejestr-certyfikowanych-instalatorow-wydanych-certyfikatow-i-ich-wtornikow' target='_blank' rel='noopener noreferrer'>certyfikatem OZE</a> wydanym przez <a href='https://www.udt.gov.pl/' target='_blank' rel='noopener noreferrer'>UDT</a> możesz być pewny że twoja instalacja zostanie zamontowana zgodnie z obowiązującymi normami i przepisami p.poż.</p>
                        <Lightbox
                            images={images}
                            renderImageFunc={(idx, image, toggleLightbox) => {
                                return (
                                    <img
                                        key={idx}
                                        src={image.src}
                                        width={image.width}
                                        height={image.height}
                                        alt={image.description}
                                        onClick={toggleLightbox.bind(null, idx)} />
                                );
                            }}
                        />
                        <a href='https://www.udt.gov.pl/certyfikacja-instalatorow-oze/rejestr-certyfikowanych-instalatorow-wydanych-certyfikatow-i-ich-wtornikow' target='_blank' rel='noopener noreferrer' className='button'>Sprawdź certyfikowanych instalatorów</a>
                    </div>
                </section>
            
                <section id='partners'>
                    <div className='container'>
                        <p className='h3'>Pracujemy z:</p>
                        <div className='slider'>
                            <Slider {...settingsPartners}>
                                <figure>
                                    <img src={require('../gfx/partnerzy/ampio.png')} alt='Ampio Smart Home' width='160' height='80' />
                                </figure>
                                <figure>
                                    <img src={require('../gfx/partnerzy/canadian-solar.png')} alt='Canadian Solar' width='160' height='80' />
                                </figure>
                                <figure>
                                    <img src={require('../gfx/partnerzy/dream-heat.png')} alt='Longi Solar' width='160' height='80' />
                                </figure>
                                <figure>
                                    <img src={require('../gfx/partnerzy/eaton.png')} alt='Eaton' width='160' height='80' />
                                </figure>
                                <figure>
                                    <img src={require('../gfx/partnerzy/eti.png')} alt='ETI' width='160' height='80' />
                                </figure>
                                <figure>
                                    <img src={require('../gfx/partnerzy/goodwe.png')} alt='Goodwe' width='160' height='80' />
                                </figure>
                                <figure>
                                    <img src={require('../gfx/partnerzy/ja-solar.png')} alt='JA Solar' width='160' height='80' />
                                </figure>
                                <figure>
                                    <img src={require('../gfx/partnerzy/novotegra.png')} alt='Novotegra' width='160' height='80' />
                                </figure>
                                <figure>
                                    <img src={require('../gfx/partnerzy/longi.png')} alt='Longi Solar' width='160' height='80' />
                                </figure>
                                <figure>
                                    <img src={require('../gfx/partnerzy/noark.png')} alt='Noark' width='160' height='80' />
                                </figure>
                                <figure>
                                    <img src={require('../gfx/partnerzy/santon.png')} alt='Santon' width='160' height='80' />
                                </figure>
                                <figure>
                                    <img src={require('../gfx/partnerzy/sonel.png')} alt='Sonel' width='160' height='80' />
                                </figure>
                                <figure>
                                    <img src={require('../gfx/partnerzy/solaredge.png')} alt='SolarEdge' width='160' height='80' />
                                </figure>
                                <figure>
                                    <img src={require('../gfx/partnerzy/spomasz-naklo.png')} alt='Spomasz Nakło' width='160' height='80' />
                                </figure>
                            </Slider>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}