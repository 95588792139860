import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

export default class Offer extends Component {
    constructor() {
        super();
    }

    render() {
        return (
            <div className='container'>
                <Helmet>
                    <meta name="description" content="Sprawdź ofertę fotowoltaiki - dla domu, firmy i rolnika" />
                    <meta name="keywords" content="oferta fotowoltaiki, oferta instalacji fotowoltaicznej" />
                    <link rel="canonical" href="https://raz-dwa.pl/fotowoltaika/oferta" />
                    <title>Raz Dwa - Fotowoltaika - Oferta</title>
                </Helmet>

                <section className='gridStack'>
                    <div className='container'>
                        <p className='h3'>Oferta</p>
                        <div className='item'>
                            <div className='left'>
                                <img src={require('../../gfx/oferta/home.jpg')} alt='Dla domu' width='465' height='349' />
                            </div>
                            <div className='right'>
                                <h3><Link to='/fotowoltaika/oferta/dla-domu'>Dla domu</Link></h3>
                                <p>Instalacje fotowoltaiczne to nowoczesne źródła zielonej energii, które obniży Twoje rachunki za prąd. Uzyskaj niezależność energetyczną i nie martw się już więcej podwyżkami za prąd.</p>
                                <button>Więcej</button>
                            </div>
                        </div>
                        <div className='item reverse'>
                            <div className='left'>
                                <img src={require('../../gfx/oferta/1.jpg')} alt='Dla rolnika' width='465' height='349' />
                            </div>
                            <div className='right'>
                                <h3><Link to='/fotowoltaika/oferta/dla-rolnika'>Dla rolnika</Link></h3>
                                <p>Wraz z postępującą mechanizacją gospodarstw rolnych XXI wieku wzrasta ich zapotrzebowanie na energię elektryczną. Instalacja fotowoltaiczna dla rolnika to wybór, który obniży rachunki za energię elektryczną i zmniejszy koszty utrzymania gospodarstwa.</p>
                                <button>Więcej</button>
                            </div>
                        </div>
                        <div className='item'>
                            <div className='left'>
                                <img src={require('../../gfx/oferta/company.jpg')} alt='Dla firmy' width='465' height='349' />
                            </div>
                            <div className='right'>
                                <h3><Link to='/fotowoltaika/oferta/dla-firmy'>Dla firmy</Link></h3>
                                <p>Podwyżki cen prądu uderzają również w przedsiębiorców w konsekwencji czego rosną ceny za oferowane produkty czy usługi. Inwestując w odpowiednio dobraną instalację fotowoltaiczną można obniżyć rachunki za energię elektryczną do minimum i zmniejszyć koszty prowadzenia działalności.</p>
                                <button>Więcej</button>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}