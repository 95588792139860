import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

export default class Installations extends Component {
    constructor() {
        super();
    }

    render() {
        return (
            <div className='container'>
                <Helmet>
                    <meta name="description" content="Świadczymy profesjonalne usługi z zakresu montażu instalacji elektrycznych" />
                    <meta name="keywords" content="instalacje elektryczne, montaż instalacji elektrycznej" />
                    <link rel="canonical" href="https://raz-dwa.pl/instalacje-elektryczne/instalacje" />
                    <title>Raz Dwa - Instalacje elektryczne - Instalacje</title>
                </Helmet>

                <section className='withBanner'>
                    <p className='h3'>Instalacje elektryczne</p>
                    <img src={require('../../gfx/elektryka/instalacje.jpg')} alt='Instalacje elektryczne' width='960' height='520' />
                    <p>Świadczymy profesjonalne usługi z zakresu montażu instalacji elektrycznych. Szeroki zakres usług wykonujemy z należytą starannością i dokładnością przez wykwalifikowaną kadrę z niezbędnymi uprawnieniami. Specjalizujemy się w kompleksowej realizacji inwestycji w instalacje elektryczne.</p>
                    <p>Wykonujemy montaż instalacji elektrycznych w mieszkaniach, domach jedno i wielorodzinnych, biurach, lokalach usługowych, budynkach gospodarczych oraz budynkach o przeznaczeniu technicznym. Montując instalację elektryczną kierujemy się normami bezpieczeństwa oraz jej funkcjonalnością i wygodą przyszłych użytkowników.</p>
                    <p><strong>Zakres naszych usług:</strong></p>
                    <ul>
                        <li>Instalacje elektryczne</li>
                        <li>Uziemienie</li>
                        <li>Rozdzielnice elektryczne</li>
                        <li>Pomiary elektryczne</li>
                        <li>Automatyka domowa i przemysłowa</li>
                        <li>Inteligentne domy</li>
                        <li>Systemy monitoringu</li>
                        <li>Systemy domofonowe</li>
                        <li>Instalacje fotowoltaiczne</li>
                    </ul>
                </section>
            </div>
        );
    }
}