import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';

export default class Header extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const a = this;

        document.addEventListener('scroll', function() {
            a.toggleVisibility();
        });
    }

    toggleVisibility() {
        const fixedHeader = document.getElementsByTagName('header')[0];

        if (window.pageYOffset > 150) {
            fixedHeader.classList.add('fixed');
        } else {
            fixedHeader.classList.remove('fixed');
        }
    }

    mobileMenu(e) {
        const hamburger = document.getElementsByClassName('navTrigger')[0];

        e.preventDefault();

        document.getElementsByTagName('html')[0].classList.toggle('navOpen');

        if (hamburger.querySelector('span').classList.contains('icon-hamburger')) {
            hamburger.querySelector('span').classList.add('icon-close');
            hamburger.querySelector('span').classList.remove('icon-hamburger');
            hamburger.classList.add('show');
        } else {
            hamburger.querySelector('span').classList.remove('icon-close');
            hamburger.querySelector('span').classList.add('icon-hamburger');
            hamburger.classList.remove('show');
        }
    }

    removeNav() {
        const hamburger = document.getElementsByClassName('navTrigger')[0];

        document.getElementsByTagName('html')[0].classList.remove('navOpen');
        hamburger.querySelector('span').classList.remove('icon-close');
        hamburger.querySelector('span').classList.add('icon-hamburger');
    }

    parentToggle(e) {
        e.currentTarget.classList.toggle('active');
        e.currentTarget.nextSibling.classList.toggle('active');
    }

    render() {
        return (
            <header>
                <div className='navPlaceholder'>
                    <div className='container'>
                        <div className='logo'>
                            <Link to='/'><img src={require('../gfx/logotyp.png')} alt='logotyp' width='100' height='86'/></Link>
                        </div>
                        <nav className='navContainer' id='offcanvas'>
                            <ul className='nav'>
                                <li>
                                    <p className='parentMenu' onClick={this.parentToggle}><em>Instalacje elektryczne</em> <span className='icon icon-arrow-down'></span></p>
                                    <ul>
                                        <li><NavLink activeClassName='active' to='/instalacje-elektryczne/instalacje' onClick={this.removeNav}>Instalacje</NavLink></li>
                                        <li><NavLink activeClassName='active' to='/instalacje-elektryczne/pomiary' onClick={this.removeNav}>Pomiary</NavLink></li>
                                        <li><NavLink activeClassName='active' to='/instalacje-elektryczne/automatyka' onClick={this.removeNav}>Automatyka</NavLink></li>
                                    </ul>
                                </li>
                                <li>
                                    <p className='parentMenu' onClick={this.parentToggle}><em>Fotowoltaika</em> <span className='icon icon-arrow-down'></span></p>
                                    <ul>
                                        <li><NavLink activeClassName='active' to='/fotowoltaika/oferta' onClick={this.removeNav}>Oferta</NavLink></li>
                                        <li><NavLink activeClassName='active' to='/fotowoltaika/kalkulator' onClick={this.removeNav}>Kalkulator</NavLink></li>
                                        <li><NavLink activeClassName='active' to='/fotowoltaika/finansowanie' onClick={this.removeNav}>Finansowanie</NavLink></li>
                                        <li><NavLink activeClassName='active' to='/fotowoltaika/zasada-dzialania' onClick={this.removeNav}>Zasada działania</NavLink></li>
                                    </ul>
                                </li>
                                <li>
                                    <p className='parentMenu' onClick={this.parentToggle}><em>Inteligentne domy</em> <span className='icon icon-arrow-down'></span></p>
                                    <ul>
                                        <li><NavLink activeClassName='active' to='/inteligentne-domy/funkcjonalnosci' onClick={this.removeNav}>Funkcjonalności</NavLink></li>
                                        <li><NavLink activeClassName='active' to='/inteligentne-domy/cennik' onClick={this.removeNav}>Cennik</NavLink></li>
                                    </ul>
                                </li>
                                <li>
                                    <p className='parentMenu' onClick={this.parentToggle}><em>Ogrzewanie</em> <span className='icon icon-arrow-down'></span></p>
                                    <ul>
                                        <li><NavLink activeClassName='active' to='/ogrzewanie/folie-grzewcze' onClick={this.removeNav}>Folie grzewcze</NavLink></li>
                                        <li><NavLink activeClassName='active' to='/ogrzewanie/termostaty' onClick={this.removeNav}>Termostaty</NavLink></li>
                                        <li><NavLink activeClassName='active' to='/ogrzewanie/strefa-wiedzy' onClick={this.removeNav}>Strefa wiedzy</NavLink></li>
                                        <li><NavLink activeClassName='active' to='/ogrzewanie/do-pobrania' onClick={this.removeNav}>Do pobrania</NavLink></li>
                                    </ul>
                                </li>
                                <li><NavLink activeClassName='active' to='/kontakt' onClick={this.removeNav}>Kontakt</NavLink></li>
                            </ul>
                        </nav>
            
                        <a href='#offcanvas' className='navTrigger' onClick={this.mobileMenu} aria-label='mobileMenu'>
                            <span className='icon-hamburger'></span>
                        </a>
                    </div>
                </div>
            </header>
        );
    }
}