import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import ScrollToTop from 'react-router-scroll-top';
import Breadcrumbs  from 'react-router-dynamic-breadcrumbs';
import { createBrowserHistory } from 'history';
import { Link } from 'react-router-dom';

// Global
import Header from './Header';
import Footer from './Footer';
import Home from './Home';

// Electrical Installations
import ElectricalInstallations from './ElectricalInstallations/ElectricalInstallations';
import Installations from './ElectricalInstallations/Installations';
import Measurement from './ElectricalInstallations/Measurement';
import Automation from './ElectricalInstallations/Automation';

// Photovoltaic
import Photovoltaics from './Photovoltaics/Photovoltaics';
import PhotovoltaicsOffer from './Photovoltaics/Offer';
import ForHouse from './Photovoltaics/ForHouse';
import ForFarmer from './Photovoltaics/ForFarmer';
import ForCompany from './Photovoltaics/ForCompany';
import Calc from './Photovoltaics/Calc';
import Financing from './Photovoltaics/Financing';
import PrincipleOfOperation from './Photovoltaics/PrincipleOfOperation';

// Smart home
import SmartHome from './SmartHome/SmartHome';
import PriceList from './SmartHome/PriceList';
import Functionalities from './SmartHome/Functionalities';
// Functionalities
import Garden from './SmartHome/Functionalities/Garden';
import GatesAndShutters from './SmartHome/Functionalities/GatesAndShutters';
import HeatingAndVentilation from './SmartHome/Functionalities/HeatingAndVentilation';
import Lighting from './SmartHome/Functionalities/Lighting';
import Multimedia from './SmartHome/Functionalities/Multimedia';
import Security from './SmartHome/Functionalities/Security';

// Heating systems
import HeatingSystems from './HeatingSystems/HeatingSystems';
import HeatingFoils from './HeatingSystems/HeatingFoils';
import Thermostats from './HeatingSystems/Thermostats';
import KnowledgeZone from './HeatingSystems/KnowledgeZone';
import HowMuchItCosts from './HeatingSystems/KnowledgeZone/HowMuchItCosts';
import Fuels from './HeatingSystems/KnowledgeZone/Fuels';
import ComparisonOfElectricHeatingMethods from './HeatingSystems/KnowledgeZone/ComparisonOfElectricHeatingMethods';
import Downloadable from './HeatingSystems/Downloadable';

// Other pages
import Contact from './Contact';
import PrivacyPolicy from './PrivacyPolicy';
import Error from './Error';

import CookieConsent from 'react-cookie-consent';

// Styles
import '../styles/_header.scss';
import '../styles/_footer.scss';
import '../styles/home.scss';
import '../styles/calc.scss';
import '../styles/downloadable.scss';
import '../styles/contact.scss';

export default class App extends Component {
    constructor(props) {
        super(props);

        this.history = createBrowserHistory();
    }

    componentDidUpdate() {
        this.history = createBrowserHistory();
    }

    render() {
        const routes = [
            {path: '/', name: 'Raz Dwa', component: Home},
            {path: '/instalacje-elektryczne', name: 'Instalacje elektryczne', component: ElectricalInstallations},
            {path: '/instalacje-elektryczne/instalacje', name: 'Instalacje', component: Installations},
            {path: '/instalacje-elektryczne/pomiary', name: 'Pomiary', component: Measurement},
            {path: '/instalacje-elektryczne/automatyka', name: 'Automatyka', component: Automation},
            {path: '/fotowoltaika', name: 'Fotowoltaika', component: Photovoltaics},
            {path: '/fotowoltaika/oferta', name: 'Oferta', component: PhotovoltaicsOffer},
            {path: '/fotowoltaika/oferta/dla-domu', name: 'Dla domu', component: ForHouse},
            {path: '/fotowoltaika/oferta/dla-rolnika', name: 'Dla rolnika', component: ForFarmer},
            {path: '/fotowoltaika/oferta/dla-firmy', name: 'Dla firmy', component: ForCompany},
            {path: '/fotowoltaika/kalkulator', name: 'Kalkulator', component: Calc},
            {path: '/fotowoltaika/finansowanie',  name: 'Finansowanie', component: Financing},
            {path: '/fotowoltaika/zasada-dzialania', name: 'Zasada działania', component: PrincipleOfOperation},
            {path: '/inteligentne-domy', name: 'Inteligentne zarządzanie domem', component: SmartHome},
            {path: '/inteligentne-domy/cennik', name: 'Oszczędność', component: PriceList},
            {path: '/inteligentne-domy/funkcjonalnosci', name: 'Funkcjonalności', component: Functionalities},
            {path: '/inteligentne-domy/funkcjonalnosci/ogrod', name: 'Ogród', component: Garden},
            {path: '/inteligentne-domy/funkcjonalnosci/oswietlenie', name: 'Oświetlenie', component: Lighting},
            {path: '/inteligentne-domy/funkcjonalnosci/ogrzewanie-i-wentylacja', name: 'Ogrzewanie i wentylacja', component: HeatingAndVentilation},
            {path: '/inteligentne-domy/funkcjonalnosci/bramy-i-rolety', name: 'Bramy i rolety', component: GatesAndShutters},
            {path: '/inteligentne-domy/funkcjonalnosci/bezpieczenstwo', name: 'Bezpieczeństwo', component: Security},
            {path: '/inteligentne-domy/funkcjonalnosci/multimedia', name: 'Multimedia', component: Multimedia},
            {path: '/ogrzewanie', name: 'Ogrzewanie', component: HeatingSystems},
            {path: '/ogrzewanie/folie-grzewcze', name: 'Korzyści', component: HeatingFoils},
            {path: '/ogrzewanie/termostaty', name: 'Koszt i czas realizacji', component: Thermostats},
            {path: '/ogrzewanie/strefa-wiedzy', name: 'Strefa wiedzy', component: KnowledgeZone},
            {path: '/ogrzewanie/strefa-wiedzy/ile-to-kosztuje', name: 'Ile to kosztuje', component: HowMuchItCosts},
            {path: '/ogrzewanie/strefa-wiedzy/paliwa', name: 'Paliwa', component: Fuels},
            {path: '/ogrzewanie/strefa-wiedzy/porownanie-elektrycznych-metod-ogrzewania', name: 'Porównanie elektrycznych metod ogrzewania', component: ComparisonOfElectricHeatingMethods},
            {path: '/ogrzewanie/do-pobrania', name: 'Koszt i czas realizacji', component: Downloadable},
            {path: '/kontakt', name: 'Kontakt', component: Contact},
            {path: '/polityka-prywatnosci', name: 'Polityka prywatności', component: PrivacyPolicy},
            {path: '*', name: 'Nie znaleziono strony', component: Error}
        ];

        const routess = {
            '/': 'Raz Dwa',
            '/instalacje-elektryczne': 'Instalacje elektryczne',
            '/instalacje-elektryczne/instalacje': 'Instalacje',
            '/instalacje-elektryczne/pomiary': 'Pomiary',
            '/instalacje-elektryczne/automatyka': 'Automatyka',
            '/fotowoltaika': 'Fotowoltaika',
            '/fotowoltaika/oferta': 'Oferta',
            '/fotowoltaika/oferta/dla-domu': 'Dla domu',
            '/fotowoltaika/oferta/dla-rolnika': 'Dla rolnika',
            '/fotowoltaika/oferta/dla-firmy': 'Dla firmy',
            '/fotowoltaika/kalkulator': 'Kalkulator',
            '/fotowoltaika/finansowanie': 'Finansowanie',
            '/fotowoltaika/zasada-dzialania': 'Zasada działania',
            '/inteligentne-domy': 'Inteligentne domy',
            '/inteligentne-domy/cennik': 'Cennik',
            '/inteligentne-domy/funkcjonalnosci': 'Funkcjonalności',
            '/inteligentne-domy/funkcjonalnosci/ogrod': 'Ogród',
            '/inteligentne-domy/funkcjonalnosci/oswietlenie': 'Oświetlenie',
            '/inteligentne-domy/funkcjonalnosci/ogrzewanie-i-wentylacja': 'Ogrzewanie i wentylacja',
            '/inteligentne-domy/funkcjonalnosci/bramy-i-rolety': 'Bramy i rolety',
            '/inteligentne-domy/funkcjonalnosci/bezpieczenstwo': 'Bezpieczeństwo',
            '/inteligentne-domy/funkcjonalnosci/multimedia': 'Multimedia',
            '/ogrzewanie': 'Ogrzewanie',
            '/ogrzewanie/folie-grzewcze': 'Folie grzewcze',
            '/ogrzewanie/termostaty': 'Termostaty',
            '/ogrzewanie/strefa-wiedzy': 'Strefa wiedzy',
            '/ogrzewanie/strefa-wiedzy/ile-to-kosztuje': 'Ile to kosztuje',
            '/ogrzewanie/strefa-wiedzy/paliwa': 'Paliwa',
            '/ogrzewanie/strefa-wiedzy/porownanie-elektrycznych-metod-ogrzewania': 'Porównanie elektrycznych metod ogrzewania',
            '/ogrzewanie/do-pobrania': 'Do pobrania',
            '/kontakt': 'Kontakt',
            '/polityka-prywatnosci': 'Polityka prywatności',
            '*': 'Nie znaleziono strony'
        };

        return (
            <Router>
                <div className="Routing">
                    <Header />
                    <div className='breadcrumbs'>
                        <div className='container'>
                            <Breadcrumbs mappedRoutes={routess} />
                        </div>
                    </div>
                    <ScrollToTop>
                        <Switch>
                            {routes.map(({name, path, component}) =>
                                (<Route exact key={name} path={path} component={component} />)
                            )};
                        </Switch>
                    </ScrollToTop>
                    <CookieConsent
                        location="bottom"
                        declineButtonText="Odrzucam"
                        buttonText="Akceptuję"
                        cookieName="AcceptedCookie"
                        style={{ background: '#262626', fontSize: '14px' }}
                        declineButtonStyle={{ background: '#af2614', color: '#FFFFFF', fontSize: '14px', margin: '15px 20px 15px 0px' }}
                        buttonStyle={{ background: '#3f7edf', color: '#FFFFFF', fontSize: '14px', margin: '15px 40px 15px 0px' }}
                        expires={150} overlay enableDeclineButton>

                        <p>Używamy plików cookies aby strona działa poprawnie.<br/>W opcjach przeglądarki możesz zmienić ustawienia według swoich preferencji. <Link to='/polityka-prywatnosci' target="_blank" rel="noreferrer">Polityka prywatności</Link></p>
                    </CookieConsent>
                    <Footer />
                </div>
            </Router>
        );
    }
}