import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

export default class PriceList extends Component {
    constructor() {
        super();
    }

    render() {
        return (
            <div className='container'>
                <Helmet>
                    <meta name="description" content="Cennik założenia inteligentnego domu" />
                    <meta name="keywords" content="założenie inteligentnego domu" />
                    <link rel="canonical" href="https://raz-dwa.pl/inteligentne-domy/cennik" />
                    <title>Raz Dwa - Inteligentne domy - Cennik</title>
                </Helmet>

                <section className='article'>
                    <p className='h3'>Cennik</p>
                    <p>Poniżej możesz pobrać najnowszy cennik produktów i usług Ampio. Lista zawiera sugerowane ceny detaliczne produktów Ampio.</p>
                    <p>Sprzedaż detaliczna dostępna jest wyłącznie dla firm poprzez dystrybutorów współpracujących z Ampio Smart Home.</p>
                    <a href={require('../../files/Ampio_Smart_Home_-_ceny_detaliczne_produktów.pl.pdf')} target='_blank' rel='noopener noreferrer' className='button'>Pobierz cennik produktów Ampio</a>
                </section>
            </div>
        );
    }
}