import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

import '../../styles/basic.scss';

export default class ForFarmer extends Component {
    constructor() {
        super();
    }

    render() {
        return (
            <div className='container'>
                <Helmet>
                    <meta name="description" content="Oferta instalacji fotowoltaicznej dla rolnika" />
                    <meta name="keywords" content="fotowoltaika dla rolnika" />
                    <link rel="canonical" href="https://raz-dwa.pl/fotowoltaika/oferta/dla-rolnika" />
                    <title>Raz Dwa - Fotowoltaika - Oferta - Dla rolnika</title>
                </Helmet>

                <section className='article'>
                    <p className='h3'>Dla rolnika</p>
                    <p>Rozwijające się gospodarstwa inwestują w nowe technologie, które ułatwiają pracę i zwiększają możliwości produkcyjne, jest to niestety też związane z wzrostu rachunków za energię elektryczną. Aby się przed tym uchronić wystarczy zainwestować w instalację fotowoltaiczną, która będzie produkować dla Ciebie zieloną energię. W rolnictwie ważne jest efektywne wykorzystywanie dostępnych powierzchni. Panele można zamontować na wszelkiego rodzaju budynkach gospodarczych, jak i na terenach wokół nich stanowiących nieużytki rolne. Inwestycja w fotowoltaikę w gospodarstwie rolnym jest teraz jeszcze bardziej atrakcyjna dzięki programom wspierającym rozwój odnawialnych źródeł energii. Rolnicy mogą teraz skorzystać z ulgi w podatku rolnym, która wynosi 25% inwestycji i można ją rozliczać przez okres 15 lat. Dodatkowo z programu Agro Energia można uzyskać do 20% dofinansowania kosztów kwalifikowanych na instalacje fotowoltaiczną o wielkości od 10-50kWp.  Pieniądze, które do tej pory przeznaczałeś na rachunki za prąd będziesz mógł wykorzystać na inne cele, a jeżeli zamontujesz większą instalację niż jest Twoje zapotrzebowanie na energię jej nadwyżki będziesz mógł odsprzedawać do zakładu energetycznego z dodatkowym zyskiem dla Twojego gospodarstwa.</p>
                    <p className='h4'>Dotacje na fotowoltaikę dla gospodarstw rolnych</p>
                    <ol>
                        <li>
                            <strong>Ulga w podatku rolnym 25%</strong>
                            <p>Każdy rolnik, który zdecyduje się na założenie instalacji fotowoltaicznej może odliczyć od należnego padatku rolnego 25% wydatków poniesionych na inwestycje. Jeżeli nie ma możliwości wykorzystania całej ulgi w ciągu jednego roku podatkowego niewykorzystane koszty można odliczać w kolejnych latach.</p>
                        </li>
                        <li>
                            <strong>Program AgroEnergia – dofinansowanie do 20%</strong>
                            <p>Program finansowany z budżetu Narodowego Funduszu Ochrony Środowiska i Gospodarki Wodnej, ma na celu zwiększenie produkcji energii z odnawialnych źródeł w sektorze rolniczym. Dofinansowanie i kredyty obejmują instalację nowych źródeł ciepła i energii elektrycznej. Dotacja na fotowoltaikę może wynieść maksymalnie 20% kosztów kwalifikowanych przy instalacjach od 10-50kWp.</p>
                        </li>
                    </ol>
                </section>
            </div>
        );
    }
}