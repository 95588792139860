import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

import '../../styles/basic.scss';

export default class ForHouse extends Component {
    constructor() {
        super();
    }

    render() {
        return (
            <div className='container'>
                <Helmet>
                    <meta name="description" content="Oferta instalacji fotowoltaicznej dla domu" />
                    <meta name="keywords" content="fotowoltaika dla osoby prywatnej" />
                    <link rel="canonical" href="https://raz-dwa.pl/fotowoltaika/oferta/dla-domu" />
                    <title>Raz Dwa - Fotowoltaika - Oferta - Dla domu</title>
                </Helmet>

                <section className='article'>
                    <p className='h3'>Dla domu</p>
                    <p>Z roku na rok podwyżki za energie elektryczną odchudzają nasze portfele. Czas stawić temu czoło i zainwestować w fotowoltaikę. Dzięki instalacji fotowoltaicznej możesz obniżyć swoje rachunki za prąd do minimum i nie przejmować się więcej podwyżkami. Aby obniżyć koszty zakupu mikroinstalacji możesz skorzystać z dofinansowania z programu Mój prąd, a także z ulgi termoizolacyjnej i przy rocznym zeznaniu podatkowym odliczyć sobie koszty poniesione na zakup instalacji fotowoltaicznej od podatku do kwoty 53 000,00zł. Co ważne, jeżeli nie uda się odliczyć wszystkiego w pierwszym roku koszty te możemy odliczać jeszcze przez kolejne 5 lat.</p>
                    <p>Nie chcesz angażować własnych środków w zakup instalacji fotowoltaicznej. Mamy na to rozwiązanie. Dzięki współpracy z Bankiem Alior Bank, zakup instalacji fotowoltaicznej sfinansujesz bez angażowania własnych środków, przy pomocy preferencyjnych miesięcznych rat dopasowanych do Twoich obecnych rachunków za prąd.</p>
                    
                    <p className='h4'>Jak to zrobić KROK PO KROKU</p>
                    <ol>
                        <li>Skorzystaj z kalkulatora i wypełnij formularz kontaktowy.</li>
                        <li>Skontaktujemy się z Tobą, aby się spotkać i dobrać konkretne rozwiązanie.</li>
                        <li>Po podpisaniu umowy zamontujemy instalację fotowoltaiczną.</li>
                        <li>Podłączymy Twoją instalację do sieci i załatwimy wszystkie formalności z tym związane.</li>
                        <li>Gotowe możesz się cieszyć swoją własną zieloną energią.</li>
                    </ol>
        
                    <p className='h4'>Dofinansowania i dotacje na fotowoltaikę dla domu</p>
                    <section className='item'>
                        <img src={require('../../gfx/ikony/moj_prad.png')} alt=''
                            width="120"
                            height="120" />
                        <div>
                            <strong>Program „Mój prąd”</strong>
                            <p>Program „Mój prąd” to skuteczny sposób na obniżenie kosztów zakupu i montażu instalacji fotowoltaicznej. Gospodarstwa domowe, w których założono instalację fotowoltaiczną o mocy od 2-10 kWp mogą  ubiegać się o bezzwrotną dotacje, która pokryje część kosztów. Korzystając z programu „Mój prąd” można również ubiegać się o Ulgę Termoizolacyjną.</p>
                        </div>
                    </section>
                    <section className='item'>
                        <img src={require('../../gfx/ikony/ulga_termomodernizacyjna.png')} alt=''
                            width="120"
                            height="120" />
                        <div>
                            <strong>„Ulga termoizolacyjna” – nawet 53 000 zł na przestrzeni 6 lat</strong>
                            <p>„Ulga termoizolacyjna” umożliwia odliczenie od podatku dochodowego wydatków poniesionych na termomodernizację budynków jednorodzinnych a kwota odliczenia może wynieś nawet 53 000 zł. Czas jaki mamy na całkowite rozliczenie to nawet 6 lat.</p>
                        </div>
                    </section>
                    <section className='item'>
                        <img src={require('../../gfx/ikony/czyste_powietrze.png')} alt=''
                            width="120"
                            height="120" />
                        <div>
                            <strong>Program „Czyste powietrze”</strong>
                            <p>Program dla właścicieli i współwłaścicieli domów jednorodzinnych, lub wydzielonych w budynkach jednorodzinnych lokali mieszkalnych z wyodrębnioną księgą wieczystą. Dotacje do 30-37 tys. zł na wymianę źródeł ciepła i termomodernizację domu.</p>
                        </div>
                    </section>
                </section>
            </div>
        );
    }
}