import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

export default class Error extends Component {
    constructor() {
        super();
    }

    render() {
        return (
            <div className="container">
                <Helmet>
                    <meta name="robots" content="noindex, nofollow" />
                    <title>Raz Dwa - Nie znaleziono strony</title>
                </Helmet>

                <section>
                    <p className="h3">Nie mamy takiej strony &#x1F61E;</p>
                    <p><a href="/">Przejdź na stronę główną</a> lub spróbuj wejść na stronę ponownie.</p>
                </section>
            </div>
        );
    }
}