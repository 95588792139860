import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

export default class HeatingAndVentilation extends Component {
    constructor() {
        super();
    }

    render() {
        return (
            <div className='container'>
                <Helmet>
                    <meta name="description" content="Zastosowanie inteligentego domu wraz z ogrzewaniem i wentylacją" />
                    <meta name="keywords" content="inteligentny dom dla ogrzewania i wentylacji" />
                    <link rel="canonical" href="https://raz-dwa.pl/inteligentne-domy/funkcjonalnosci/ogrzewanie-i-wentylacja" />
                    <title>Raz Dwa - Inteligentne domy - Ogrzewanie i wentylacja</title>
                </Helmet>

                <section>
                    <p className='h3'>Ogrzewanie i wentylacja</p>
                    <p>Sterowanie ogrzewaniem, klimatyzacją i wentylacją</p>
                    <p>Zdalnie sterowane ogrzewanie to jedna z podstawowych funkcjonalności AMPIO Smart Home. Nasz system zadba o wygodną regulację temperatury oraz jakość powietrza w Twoim domu. Jednocześnie zoptymalizuje zużycie energii w zależności od preferencji oraz trybu dnia domowników. Pomoże też utrzymać odpowiednie warunki nawet podczas Twojej nieobecności.</p>

                    <div className='gridStack withoutButton'>
                        <div className='item'>
                            <div className='left'>
                                <img src='https://p2p6d7w4.rocketcdn.me/wp-content/uploads/2021/03/panel_lazienka.jpg' alt=''/>
                            </div>
                            <div className='right'>
                                <h2>Automatyczne zarządzanie temperaturą</h2>
                                <p>Niezależnie od tego, czy za oknem słońce czy śnieg, nasz system zadba o właściwą temperaturę w Twoim domu. Czujniki temperatury i nasłonecznienia automatycznie dostosują moc ogrzewania i klimatyzacji do panujących warunków atmosferycznych. Naszemu systemowi nie umknie też otwarte okno - czujniki po jego wykryciu zredukują temperaturę, aby oszczędzić energię.</p>
                            </div>
                        </div>
                        <div className='item reverse'>
                            <div className='left'>
                                <img src='https://p2p6d7w4.rocketcdn.me/wp-content/uploads/2021/03/dom-gotowy-na-toj-powrot.jpg' alt=''/>
                            </div>
                            <div className='right'>
                                <h2>Dom gotowy na Twój powrót</h2>
                                <p>Steruj ogrzewaniem, wentylacją i klimatyzacją gdziekolwiek jesteś. Sprawdź warunki panujące w domu kiedy nie ma Cię w pobliżu i zdalnie zmień temperaturę lub przygotuj dom na swój powrót. System Ampio Smart Home - to także dostęp do aplikacji mobilnej za pomocą komputera, tabletu czy smartfona. Inteligentny dom zawsze pod ręką.</p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}