import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

import '../../styles/basic.scss';

export default class ForCompany extends Component {
    constructor() {
        super();
    }

    render() {
        return (
            <div className='container'>
                <Helmet>
                    <meta name="description" content="Oferta instalacji fotowoltaicznej dla firmy" />
                    <meta name="keywords" content="fotowoltaika dla firmy" />
                    <link rel="canonical" href="https://raz-dwa.pl/fotowoltaika/oferta/dla-firmy" />
                    <title>Raz Dwa - Fotowoltaika - Oferta - Dla firmy</title>
                </Helmet>

                <section className='article'>
                    <p className='h3'>Dla firmy</p>
                    <p>Dotacje na fotowoltaikę dla firmy</p>
                    <ol>
                        <li>
                            <strong>Vat i podatek dochodowy</strong>
                            <p>W przypadku przedsiębiorców, fotowoltaika traktowana jest jako koszt, dlatego można odliczyć VAT i podatek dochodowy. Energia elektryczna to jeden z głównych wydatków w firmach. Z tego względu inwestycja w panele słoneczne, a więc zadbanie o źródło bezpłatnego prądu, staje się w tym przypadku bardzo opłacalna, gdyż generuje spore oszczędności.</p>
                        </li>
                        <li>
                            <strong>Programu "Energia Plus"</strong>
                            <p>Przedsiębiorcy mogą ubiegać się o dofinansowanie m. in. na instalację fotowoltaiczną z programu "Energia Plus" z którego do rozdysponowania jest 1,3 mld zł w formie pożyczki lub dotacji. Nabór wniosków i zakończy się 17.12.2021 lub po wyczerpania środków.</p>
                        </li>
                    </ol>
                </section>
            </div>
        );
    }
}