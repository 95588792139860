import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

export default class Thermostats extends Component {
    constructor() {
        super();
    }

    sunAndMoon(e) {
        e.currentTarget.classList.toggle('active');
        if (e.currentTarget.querySelector('span.icon').classList.contains('icon-sun')) {
            e.currentTarget.querySelector('span.icon').classList.remove('icon-sun');
            e.currentTarget.querySelector('span.icon').classList.add('icon-moon');
        } else {
            e.currentTarget.querySelector('span.icon').classList.remove('icon-moon');
            e.currentTarget.querySelector('span.icon').classList.add('icon-sun');
        }
    }

    render() {
        return (
            <div className='container'>
                <Helmet>
                    <meta name="description" content="Rodzaje termostatów do zastosowania w Twoim pomieszczeniu" />
                    <meta name="keywords" content="termostaty" />
                    <link rel="canonical" href="https://raz-dwa.pl/ogrzewanie/termostaty" />
                    <title>Raz Dwa - Ogrzewanie - Termostaty</title>
                </Helmet>

                <section>
                    <p className='h3'>Termostat</p>
                    <p><strong>Termostat łazienkowy</strong></p>
                    <p>W bogatym asortymencie sklepu Dream Heat znajdziesz autorskie rozwiązania do systemów ogrzewania domów i obiektów użyteczności publicznej. Wśród nich jest tani, a zarazem niezawodny <strong>programowalny termostat pokojowy</strong>, mogący służyć również jako <strong>łazienkowy</strong>.</p>
                    <p>Profesjonalne produkty służą do precyzyjnego i wygodnego sterowania temperaturą w pomieszczeniach. Charakteryzują się intuicyjną obsługą i zapewniają wysoki komfort termiczny. Są kompatybilne z systemami ogrzewania podłóg, a także instalacjami wodnymi i gazowymi. Oferowane przez nas <strong>programowalne termostaty łazienkowe i pokojowe</strong> są przeznaczone do zastosowań zarówno w warunkach domowych, jak i komercyjnych czy przemysłowych. Ich doskonały design sprawia, że świetnie komponują się z nowoczesnymi wykończeniami wnętrz.</p>
                    <p>Wyraźny interfejs użytkownika jest dobrze widoczny w dzień i nocą, a jego bardzo duża kontrastowość umożliwia bezproblemowe korzystanie z urządzeń osobom z wadami wzroku. Specjalny system montażu gwarantuje łatwą instalację modułów sterowania w dowolnym miejscu.</p>
                </section>

                <section>
                    <p><strong>Termostat pokojowy</strong></p>
                    <p>Znajdujące się w naszej ofercie <strong>termostaty pokojowe</strong> to <strong>programowalne</strong> rozwiązania kontrolujące pracę systemów grzewczych. Są dostępne w różnych kształtach i kolorach, dzięki czemu dopasujesz je do architektury wnętrz swojego mieszkania, domu albo biura. Zastosowanie podwójnego zasilania – sieciowego oraz bateryjnego – sprawia, że urządzenia nie utracą swojej konfiguracji w przypadku przerw w dostawie energii elektrycznej. Jednocześnie mogą być sterowane przy pomocy aplikacji na smartfona i smartwatcha. Wysokiej klasy sprzęt może być kontrolowany zdalnie, a dzięki zastosowanym protokołom komunikacyjnym jego sterowanie w ten sposób jest w pełni bezpieczne. Nie ma ryzyka, że poprzez system nieuprawnieni użytkownicy uzyskają dostęp do Twojej sieci domowej. Jeżeli szukasz inteligentnych, <strong>programowalnych termostatów pokojowych i łazienkowych</strong>, to zdecydowanie powinieneś zainteresować się ofertą Dream Heat. Oferujemy autorskie, profesjonalne rozwiązania, które podnoszą komfort codziennego życia.</p>
                    <p><strong>Seria NJ-3000/6000</strong> została opracowana do sterowania elektrycznymi systemami ogrzewania podłogowego, wodnego lub kotłowego na wodę / gaz. Urządzenia te są przeznaczone do użytku w nieruchomościach komercyjnych, przemysłowych i domowych.</p>

                    <div className='about'>
                        <p><strong>Funkcjonalność termostatu</strong></p>
                        <ol>
                            <li>Dostępne są funkcje takie jak Modbus / Wifi itp.</li>
                            <li>Dokładność 0,5°C – urządzenie utrzymuje temperaturę w granicach ustawionego poziomu.</li>
                            <li>Pamięć danych, gdy zasilanie jest wyłączone.</li>
                            <li>Programowalne 5 + 2 sześć okresów maksymalizuje komfort użytkowania urządzenia oraz oszczędność.</li>
                            <li>Możliwość stworzenia grupy termostatów do scentralizowanej kontroli.</li>
                            <li>Połączenie z Amazon Echo, Google Home, Tmall Genie, JFTTT.</li>
                            <li>Synchronizacja strefy czasowej, adresu, języka etc.</li>
                            <li>Brak limitu dodawania kolejnych pomieszczeń, pokoi i obsługi Smart Scene.</li>
                        </ol>

                        <p><strong>Wygląd</strong></p>
                        <ol>
                            <li>Wyrazisty czarny ekran i kolorowe ikony.</li>
                            <li>Obszar wyświetlania 50mm pomaga chronić oczy.</li>
                            <li>Soczewka ze szkła lub akrylu (do wyboru).</li>
                            <li>Dotykowe przyciski, ułatwiają programowanie.</li>
                            <li>Srebrna ramka podnosi walory estetyczne urządzenia.</li>
                            <li>Urządzenie „odstaje” od ściany tylko 13 mm.</li>
                            <li>Specjalny system podłączenia zapewnia łatwą instalację.</li>
                            <li>Do montażu wystarcza puszka ukryta 86 mm i europejska okrągła puszka 60 mm.</li>
                            <li>Biała, czarna lub złota obudowa do wyboru.</li>
                        </ol>
                    </div>

                    <div className='twoStack sunAndMoon'>
                        <div className='item' onClick={this.sunAndMoon}>
                            <span className='icon icon-sun'></span>
                            <img src={require('../../gfx/ogrzewanie/termostaty/termo1-400x400.png')} alt="" />
                            <img src={require('../../gfx/ogrzewanie/termostaty/termo2-400x400.png')} alt="" />
                            <p>NJ-3000</p>
                        </div>
                        <div className='item' onClick={this.sunAndMoon}>
                            <span className='icon icon-moon'></span>
                            <img src={require('../../gfx/ogrzewanie/termostaty/termo4-400x400.png')} alt="" />
                            <img src={require('../../gfx/ogrzewanie/termostaty/termo3-400x400.png')} alt="" />
                            <p>NJ-6000</p>
                        </div>
                        <span className='clear'></span>
                    </div>
                </section>

                <iframe className='fullSize' width="650" height="365" src="https://www.youtube.com/embed/1SDS-_TpHxo" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>

                <section>
                    <div className='twoStack'>
                        <div className='item'>
                            <img src={require('../../gfx/ogrzewanie/termostaty/nj002dh-800x800.png')} alt="" />
                            <p>NJ-002</p>
                        </div>
                        <div className='item'>
                            <img src={require('../../gfx/ogrzewanie/termostaty/nj003-400x396.jpg')} alt="" />
                            <p>NJ-003</p>
                        </div>
                        <span className='clear'></span>
                    </div>
                </section>
            </div>
        );
    }
}