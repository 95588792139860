import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

export default class Measurement extends Component {
    constructor() {
        super();
    }

    render() {
        return (
            <div className='container'>
                <Helmet>
                    <meta name="description" content="Świadczymy profesjonalne usługi z zakresu przeglądów i pomiarów bezpieczeństwa instalacji elektrycznych" />
                    <meta name="keywords" content="przegląd instalacji elektrycznej, pomiary bezpieczeństwa instalacji elektrycznych" />
                    <link rel="canonical" href="https://raz-dwa.pl/instalacje-elektryczne/pomiary" />
                    <title>Raz Dwa - Instalacje elektryczne - Pomiary</title>
                </Helmet>

                <section className='withBanner'>
                    <p className='h3'>Pomiary elektryczne</p>
                    <img src={require('../../gfx/elektryka/pomiary.jpg')} alt='Pomiary' width='960' height='520' />
                    <p>Świadczymy profesjonalne usługi z zakresu przeglądów i pomiarów bezpieczeństwa instalacji elektrycznych.</p>
                    <p><strong>Zakres usług:</strong></p>
                    <ul>
                        <li>Pomiary odbiorcze instalacji elektrycznej</li>
                        <li>Pomiary instalacji fotowoltaicznych</li>
                        <li>Pomiary okresowe</li>
                        <li>Pomiary rezystancji uziemienia</li>
                        <li>Pomiary rezystancji izolacji</li>
                        <li>Pomiary rezystancji / ciągłości przewodów ochronnych</li>
                        <li>Pomiary wyłączników różnicowo - prądowych</li>
                        <li>Pomiary impedancji pętli zwarcia, ochrona przeciwporażeniowa</li>
                        <li>I wiele innych</li>
                    </ul>
                    <p>Usługi wykonujemy z należytą starannością i dokładnością przez wykwalifikowaną kadrę z niezbędnymi uprawnieniami. Realizowane pomiary wykonywane są zgodnie z obowiązującymi normami za pomocą atestowanych i certyfikowanych urządzeń pomiarowych. Po zakończonych pracach pomiarowych wystawiamy odpowiednie certyfikaty wymagane  przez dostawców energii czy towarzystwa ubezpieczeniowe.</p>
                    <p><strong>Dlaczego należy wykonywać pomiary elektryczne?</strong></p>
                    <p>Pomiary elektryczne należy wykonywać w celu zapewnienia bezpieczeństwa użytkownikom instalacji oraz całego budynku. Ponadto obowiązek przeprowadzania pomiarów elektrycznych nakłada na właściciela lub zarządcę budynku ustawa z dnia 7 lipca 1994 r. Prawo Budowlane (Dz. U. 1994 Nr 89 poz. 414) dzieląc je na dwa rodzaje:</p>
                    <ul>
                        <li>Pomiary elektryczne odbiorcze</li>
                        <li>Pomiary elektryczne okresowe</li>
                    </ul>
                    <p>Dodatkowo przy powstaniu szkody w ubezpieczonym budynku na skutek wyładowania atmosferycznego, przepięcia, zwarcia instalacji itp. Ubezpieczyciel poprosi pierwsze o protokół z pomiarów elektrycznych, którego nieprzedłożenie będzie podstawą do odmowy wypłaty odszkodowania.</p>
                </section>
            </div>
        );
    }
}