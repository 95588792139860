import React, { Component } from 'react';

export default class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            is_visible: false
        };
    }

    componentDidMount() {
        const a = this;
        document.addEventListener('scroll', function() {
            a.toggleVisibility();
        });
    }

    toggleVisibility() {
        if (window.pageYOffset > 200) {
            this.setState({
                is_visible: true
            });
        } else {
            this.setState({
                is_visible: false
            });
        }
    }

    scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    render() {
        const { is_visible } = this.state;

        return (
            <footer>
                <div className='container'>
                    <img className='cert' src={require('../gfx/certyfikaty/cert_ibk.png')} width='110' height='109' alt='Certyfikat Instytutu Badań Konsumenckich' />
                    <p>© 2021 - {new Date().getFullYear()} Raz Dwa</p>
                
                    {is_visible && (
                        <div id='scrollTop' onClick={() => this.scrollToTop()}>
                            <span className='arrow icon-arrow-up'></span>
                        </div>
                    )}
                </div>
            </footer>
        );
    }
}