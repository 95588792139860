import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

export default class Functionalities extends Component {
    constructor() {
        super();
    }

    render() {
        return (
            <div className='container'>
                <Helmet>
                    <meta name="description" content="Lista funkcjonalności, które daje wdrożenie inteligentego domu" />
                    <meta name="keywords" content="inteligentny dom" />
                    <link rel="canonical" href="https://raz-dwa.pl/inteligentne-domy/funkcjonalnosci" />
                    <title>Raz Dwa - Inteligentne domy - Funkcjonalności</title>
                </Helmet>

                <section>
                    <p className='h3'>Funkcjonalności</p>

                    <div className='gridStack'>
                        <div className='item'>
                            <div className='left'>
                                <img src='https://p2p6d7w4.rocketcdn.me/wp-content/uploads/2021/03/Mask-Group-3.png' alt=''/>
                            </div>
                            <div className='right'>
                                <h3><Link to='/inteligentne-domy/funkcjonalnosci/oswietlenie'>Oświetlenie</Link></h3>
                                <p>Oświetlenie to ważny element Twojego domu. Włączaj i wyłączaj światło jednym ruchem, zmień kolor oświetlenia i stwórz idealny nastrój na wieczór filmowy. Dzięki inteligentnemu sterowaniu Ampio nie musisz wstawać, aby wyłączyć światła w domu przed snem czy włączyć oświetlenie nocne.</p>
                                <button>Więcej</button>
                            </div>
                        </div>
                        <div className='item reverse'>
                            <div className='left'>
                                <img src='https://p2p6d7w4.rocketcdn.me/wp-content/uploads/2021/03/Mask-Group-9.png' alt=''/>
                            </div>
                            <div className='right'>
                                <h3><Link to='/inteligentne-domy/funkcjonalnosci/ogrzewanie-i-wentylacja'>Ogrzewanie i wentylacja</Link></h3>
                                <p>Zawsze komfortowa temperatura oraz odpowiednia jakość powietrza. Zobacz jak działają inteligentne rozwiązania AMPIO i ciesz się domem, w którym panują idealne warunki niezależnie od pory roku. Skorzystaj z harmonogramu ogrzewania, zintegrowanych czujników warunków atmosferycznych, bądź ustaw temperaturę za pomocą aplikacji.</p>
                                <button>Więcej</button>
                            </div>
                        </div>
                        <div className='item'>
                            <div className='left'>
                                <img src='https://p2p6d7w4.rocketcdn.me/wp-content/uploads/2021/03/Mask-Group-11.png' alt=''/>
                            </div>
                            <div className='right'>
                                <h3><Link to='/inteligentne-domy/funkcjonalnosci/bramy-i-rolety'>Bramy i rolety</Link></h3>
                                <p>Inteligentna obsługa bram, drzwi i rolet. Skorzystaj z możliwości sterowania jednym ruchem, bez wysiadania z auta lub automatycznie według stworzonego przez Ciebie planu. Miej wszystko pod kontrolą - niezależnie gdzie jesteś.</p>
                                <button>Więcej</button>
                            </div>
                        </div>
                        <div className='item reverse'>
                            <div className='left'>
                                <img src='https://p2p6d7w4.rocketcdn.me/wp-content/uploads/2021/03/AdobeStock_314819429.png' alt=''/>
                            </div>
                            <div className='right'>
                                <h3><Link to='/inteligentne-domy/funkcjonalnosci/bezpieczenstwo'>Bezpieczeństwo</Link></h3>
                                <p>Dobrze jest czuć się bezpiecznie i mieć wszystko pod ręką. Z Ampio Smart Home masz kontrolę nad tym co dzieje się w Twoim domu. Zintegrowane czujniki ruchu, dymu, zalania, podgląd monitoringu i kontrola dostępu do domu czy mieszkania to 100% bezpieczeństwa, także kiedy nie ma Cię w domu.</p>
                                <button>Więcej</button>
                            </div>
                        </div>
                        <div className='item'>
                            <div className='left'>
                                <img src='https://p2p6d7w4.rocketcdn.me/wp-content/uploads/2021/03/Mask-Group-13.png' alt=''/>
                            </div>
                            <div className='right'>
                                <h3><Link to='/inteligentne-domy/funkcjonalnosci/multimedia'>Multimedia</Link></h3>
                                <p>Wiele urządzeń audio, wideo czy nawet AGD możesz teraz mieć pod kontrolą, w jednym miejscu. Piekarnik podgrzeje obiad, nim wrócisz z pracy, a ekspres przygotuje świeżą kawę gdy tylko wstaniesz. Twórz sceny idealne do wieczornego oglądania filmu lub słuchaj ulubionego radia - tam gdzie akurat przebywasz.</p>
                                <button>Więcej</button>
                            </div>
                        </div>
                        <div className='item reverse'>
                            <div className='left'>
                                <img src='https://p2p6d7w4.rocketcdn.me/wp-content/uploads/2021/03/Mask-Group-12-e1616681665114.png' alt=''/>
                            </div>
                            <div className='right'>
                                <h3><Link to='/inteligentne-domy/funkcjonalnosci/ogrod'>Ogród</Link></h3>
                                <p>Inteligentny dom to także zadbany ogród i piękna zieleń trawnika. Odpowiednie nawodnienie Twojego ogrodu w zależności od aktualnych warunków pogodowych, pory roku i zaplanowanego harmonogramu. Z nami warunki dla Twoich roślin będą idealne, nawet kiedy nie ma Cię w domu.</p>
                                <button>Więcej</button>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}