/* eslint-disable react/prop-types */
/* eslint-disable no-useless-escape */
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import Nouislider from 'nouislider-react';
import ReCAPTCHA from 'react-google-recaptcha';

export default class Calc extends Component {
    constructor(props) {
        super(props);

        this.state = {
            actualSelectedPlace: '',
            actualSelectedFor: ''
        };
        this.handleChange = this.handleChange.bind(this);
        this.chooseFor = this.chooseFor.bind(this);
        this.choosePlace = this.choosePlace.bind(this);
        this.showSlider = this.showSlider.bind(this);
    }

    componentDidMount() {
        document.body.className = 'calc';
    }
    
    componentWillUnmount() {
        document.body.className = '';
    }

    componentDidUpdate(prevProps) {
        const {actualSelectedPlace, actualSelectedFor} = this.state;

        if (prevProps.actualSelectedFor !== actualSelectedFor &&
            prevProps.actualSelectedPlace !== actualSelectedPlace) {
            this.showSlider();

            if (this.refreshSlider !== undefined) {
                this.refreshSlider.click();
            }
        }
    }

    showSlider() {
        if (this.state.actualSelectedPlace && this.state.actualSelectedFor) {
            document.getElementById('fotovoltaicCalc').classList.remove('hidden');

            return <Nouislider
                connect
                ref={(input) => (this.refreshSlider = input)}
                start={[220]}
                range={{
                    min: 100,
                    max: 500
                }}
                behaviour="tap"
                format={{
                    to: function(value) {
                        return parseInt(value) + ' zł';
                    },
                    from: function(value) {
                        return Number(value.replace(',-', ''));
                    }
                }}
                onUpdate={this.handleChange}
            />;
        }
    }

    choosePlace(event) {
        let actualSelectedPlaceV = event.target.getAttribute('id');
        this.setState({actualSelectedPlace: actualSelectedPlaceV});
    }

    chooseFor(event) {
        let actualSelectedForV = event.target.getAttribute('id');
        this.setState({actualSelectedFor: actualSelectedForV});
    }

    handleChange(value) {
        // Aktualnie ustawiony suwak z cena
        let actualCost = parseInt(value),
            pitchedRoof, flatRoof, onTheGround;

        const installationCost = document.getElementById('installationCost'),
            installationCostValue = 280.44,
            costsInSliderFrom = 100, costsInSliderTo = 500;

        // Dodawanie aktualnie ustawionej ceny do artykulu
        document.getElementById('costsFrom').innerHTML = costsInSliderFrom + ' zł';
        document.getElementById('costsTo').innerHTML = costsInSliderTo + ' zł';

        // Miesieczny rachunek za prad
        document.getElementById('monthlyPayment').value = actualCost+' zł';

        // Rocznie wydajesz za prad
        document.getElementById('yearlyCost').value = (actualCost * 12).toLocaleString()+' zł';

        // Szacowana wielkosc mocy instalacji fotowoltaicznej
        document.getElementById('fotoVoltaicPower').value = (actualCost * 0.0213).toFixed(2)+' kWp';

        pitchedRoof = (actualCost * 110.5);
        flatRoof = (actualCost * 114);
        onTheGround = (actualCost * 117.5);

        function calcPercent(num) {
            return Math.ceil(num-(num*0.1));
        }

        var beforeDiscount;

        if (this.state.actualSelectedPlace != undefined && installationCost != undefined && this.state.actualSelectedFor != undefined) {
            if (this.state.actualSelectedFor == 'dom') {
                if (this.state.actualSelectedPlace === 'pitched') {
                    beforeDiscount = Math.ceil((pitchedRoof-((pitchedRoof/1000000)*pitchedRoof)));
                    installationCost.value = (calcPercent(beforeDiscount)).toLocaleString()+' zł';
                } else if (this.state.actualSelectedPlace === 'flat') {
                    beforeDiscount = Math.ceil((flatRoof-((flatRoof/1000000)*flatRoof)));
                    installationCost.value = (calcPercent(beforeDiscount)).toLocaleString()+' zł';
                } else if (this.state.actualSelectedPlace === 'ground') {
                    beforeDiscount = Math.ceil((onTheGround-((onTheGround/1000000)*onTheGround)));
                    installationCost.value = (calcPercent(beforeDiscount)).toLocaleString()+' zł';
                }
            } else {
                if (this.state.actualSelectedPlace === 'pitched') {
                    beforeDiscount = Math.ceil(((pitchedRoof-((pitchedRoof/1000000)*pitchedRoof))/1.08*1.23));
                    installationCost.value = (calcPercent(beforeDiscount)).toLocaleString()+' zł';
                } else if (this.state.actualSelectedPlace === 'flat') {
                    beforeDiscount = Math.ceil(((flatRoof-((flatRoof/1000000)*flatRoof))/1.08*1.23));
                    installationCost.value = (calcPercent(beforeDiscount)).toLocaleString()+' zł';
                } else if (this.state.actualSelectedPlace === 'ground') {
                    beforeDiscount = Math.ceil(((onTheGround-((onTheGround/1000000)*onTheGround))/1.08*1.23));
                    installationCost.value = (calcPercent(beforeDiscount)).toLocaleString()+' zł';
                }
            }
        }

        // Przez 25 lat wydasz na energie
        document.getElementById('twentyFiveYears').value = (actualCost * 300).toLocaleString()+' zł';
        // - zakladajac wzrost cen pradu 2% przez rok
        document.getElementById('twentyFiveYearsIncrease').value = (actualCost * 385).toLocaleString()+' zł';
        
        // Nie musi tak byc. Oszczedzisz przez 25 lat uwzgledniajac koszty zakupu
        document.getElementById('twentyFiveYearsIfYouBuy').value = ((actualCost * 255) - parseInt(installationCostValue)).toLocaleString()+' zł';
        // - zakladajac wzrost cen pradu 2% przez rok
        document.getElementById('twentyFiveYearsIfYouBuyIncrease').value = ((actualCost * 282) - parseInt(installationCostValue)).toLocaleString()+' zł';
    }

    handleSubmit(e) {
        e.preventDefault();

        const data = new FormData(e.target),
            response = document.getElementById('response');

        response.classList.add('sending');
        response.innerHTML = 'Wysyłanie maila...';

        fetch('../scripts/calc.php', {
            method: 'POST',
            body: data
        }).then(data => {
            if (data.status !== 200) {
                response.className = '';
                response.classList.add('error');
                response.innerHTML = 'Mail nie został wysłany, spróbuj ponownie.';
            } else {
                response.className = '';
                response.classList.add('successful');
                response.innerHTML = 'Mail został wysłany poprawnie.';
            }
        }).catch(() => {
            response.className = '';
            response.classList.add('error');
            response.innerHTML = 'Mail nie został wysłany, spróbuj ponownie.';
        });
    }

    render() {
        return (
            <div id='calc'>
                <Helmet>
                    <meta name="description" content="Wylicz koszta założenia instalacji fotowoltaicznej" />
                    <meta name="keywords" content="kalkulator założenia instalacji fotowoltaicznej, kalkulator założenia fotowoltaiki" />
                    <link rel="canonical" href="https://raz-dwa.pl/fotowoltaika/kalkulator" />
                    <title>Raz Dwa - Fotowoltaika - Kalkulator</title>
                </Helmet>

                <div className='container'>
                    <p className='h3'>Kalkulator fotowoltaiki</p>
                    <p className='requiredArea main'>Pola oznaczone <em>*</em> są obowiązkowe</p>

                    <form onSubmit={(this.handleSubmit)}>
                        <div className='choose'>
                            <p className='requiredArea'><em>*</em> Wybierz dla kogo instalacja</p>
                            <fieldset id='chooseFor'>
                                <div className='chooseOption'>
                                    <input type='radio' id='dom' name='chooseFor' value='dla domu' onChange={this.chooseFor} required />
                                    <label htmlFor='dom'>Dla domu</label>
                                </div>
        
                                <div className='chooseOption'>
                                    <input type='radio' id='rolnik' name='chooseFor' value='dla rolnika' onChange={this.chooseFor} required />
                                    <label htmlFor='rolnik'>Dla rolnika</label>
                                </div>
        
                                <div className='chooseOption'>
                                    <input type='radio' id='firma' name='chooseFor' value='dla firmy' onChange={this.chooseFor} required />
                                    <label htmlFor='firma'>Dla firmy</label>
                                </div>
                            </fieldset>
                        </div>
        
                        <div className='choose'>
                            <p className='requiredArea'><em>*</em> Wybierz miejsce instalacji</p>
                            <fieldset id='choosePlace'>
                                <div className='chooseOption'>
                                    <input type='radio' id='pitched' name='choosePlace' value='dach skośny' onChange={this.choosePlace} required />
                                    <label htmlFor='pitched'>Dach skośny</label>
                                </div>
        
                                <div className='chooseOption'>
                                    <input type='radio' id='flat' name='choosePlace' value='dach płaski' onChange={this.choosePlace} required />
                                    <label htmlFor='ground'>Dach płaski</label>
                                </div>
        
                                <div className='chooseOption'>
                                    <input type='radio' id='ground' name='choosePlace' value='na gruncie' onChange={this.choosePlace} required />
                                    <label htmlFor='ground'>Na gruncie</label>
                                </div>
                            </fieldset>
                        </div>
        
                        <div id='fotovoltaicCalc' className='hidden'>
                            <div className='item'>
                                <span className='image'></span>
                                <div className='content'>
                                    <strong>Rocznie wydajesz za prąd</strong>
                                    <p><input type='text' id='yearlyCost' name='yearlyCost' readOnly='readonly' /></p>
                                </div>
                            </div>
        
                            <div className='item'>
                                <span className='image'></span>
                                <div className='content'>
                                    <strong>Szacowana wielkość mocy instalacji fotowoltaicznej</strong>
                                    <p><input type='text' id='fotoVoltaicPower' name='fotoVoltaicPower' readOnly='readonly' /></p>
                                </div>
                            </div>
        
                            <div className='item'>
                                <span className='image'></span>
                                <div className='content'>
                                    <strong>Koszt instalacji</strong>
                                    <p><input type='text' id='installationCost' name='installationCost' readOnly='readonly' /></p>
                                </div>
                            </div>
        
                            <div className='item'>
                                <span className='image'></span>
                                <div className='content'>
                                    <strong>Roczny rachunek za prąd z instalacją fotowoltaiczną</strong>
                                    <p><input type='text' id='yearlyCostElectricityWithFotoVoltaic' name='yearlyCostElectricityWithFotoVoltaic' value='280.44 zł' readOnly='readonly' /></p>
                                </div>
                            </div>
        
                            <div className='item'>
                                <span className='image'></span>
                                <div className='content'>
                                    <strong>Przez 25 lat wydasz na energię</strong>
                                    <p><input type='text' id='twentyFiveYears' name='twentyFiveYears' readOnly='readonly' /></p>
                                    <strong>- zakładając wzrost cen prądu 2% /rok</strong>
                                    <p><input type='text' id='twentyFiveYearsIncrease' name='twentyFiveYearsIncrease' readOnly='readonly' /></p>
                                </div>
                            </div>
        
                            <div className='item'>
                                <span className='image'></span>
                                <div className='content'>
                                    <strong>Oszczędzisz przez 25 lat uwzględniając koszty zakupu</strong>
                                    <p><input type='text' id='twentyFiveYearsIfYouBuy' name='twentyFiveYearsIfYouBuy' readOnly='readonly' /></p>
                                    <strong>- zakładając wzrost cen prądu 2% /rok</strong>
                                    <p><input type='text' id='twentyFiveYearsIfYouBuyIncrease' name='twentyFiveYearsIfYouBuyIncrease' readOnly='readonly' /></p>
                                </div>
                            </div>
        
                            <div id='slider-voltaic'>
                                <p>Twój miesięczny rachunek za prąd: <input type='text' id='monthlyPayment'
                                    name='monthlyPayment' readOnly='readonly' />
                                </p>

                                { this.showSlider() }

                                <span id='costsFrom'></span><span id='costsTo'></span>
                            </div>
        
                            <section className='sendCalc'>
                                <div className='left'>
                                    <p>Powyższa kalkulacja ma charakter informacyjny i nie stanowi oferty handlowej w rozumieniu art. 66 §1 kodeksu cywilnego oraz innych właściwych przepisów prawnych.</p>
                                </div>
        
                                {document.cookie.replace(/(?:(?:^|.*;\s*)AcceptedCookie\s*\=\s*([^;]*).*$)|^.*$/, '$1') !== 'false' ?
                                    <div className='right'>
                                        <p><strong>Chcesz otrzymać kopię wyliczenia na adres e-mail?</strong></p>
                                        <div className='formSend'>
                                            <div className='field required firstname'>
                                                <label htmlFor='firstname'><em>*</em> Imię</label>
                                                <input type='text' id='firstname' name='firstname' maxLength='63' required />
                                            </div>
                                            <div className='field required lastname'>
                                                <label htmlFor='lastname'><em>*</em> Nazwisko</label>
                                                <input type='text' id='lastname' name='lastname' maxLength='63' required />
                                            </div>
                                            <div className='field required city'>
                                                <label htmlFor='city'><em>*</em> Miejscowość</label>
                                                <input type='city' id='city' name='city' maxLength='63' required />
                                            </div>
                                            <div className='field required email'>
                                                <label htmlFor='email'><em>*</em> E-mail</label>
                                                <input type='email' id='email' name='email' required />
                                            </div>
                                            <div className='field required phone'>
                                                <label htmlFor='phone'><em>*</em> Telefon</label>
                                                <input type='tel' id='phone' name='phone' maxLength='12' required />
                                            </div>
                                            <ReCAPTCHA
                                                className='g-recaptcha'
                                                sitekey= { process.env.NODE_ENV === 'development' ?
                                                    '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI' :
                                                    '6LfTfYgaAAAAANs9BxrBlsT9NqS1H-k1eWJW46IR'}
                                                onChange={this.onChange}
                                            />
                                            <div className='field required checkbox'>
                                                <input type='checkbox' value='terms' required />
                                                <label><em>*</em> Akceptuję <a href='/polityka-prywatnosci' target='_blank' rel='nofollow'>politykę prywatności</a>.</label>
                                            </div>
                                        </div>
            
                                        <p className='requiredArea'><em>*</em> Pola obowiązkowe</p>
                                        <div id="response"></div>
                                        <p><button>Wyślij</button></p>
                                    </div>
                                    : <p>Aby uzyskać możliwość wysłania wyliczenia na własnego maila potrzebujesz zaakceptować ciasteczka</p>
                                }
        
                                <span className='clear'></span>
                            </section>
                        </div>
                    </form>
        
                    <section className='textAboutCalc'>
                    
                    </section>
            
                    <section className='sendCalc'>
            
                    </section>
                </div>
            </div>
        );
    }
}