import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

export default class Automation extends Component {
    constructor() {
        super();
    }

    render() {
        return (
            <div className='container'>
                <Helmet>
                    <meta name="description" content="Świadczymy profesjonalne usługi z zakresu przeglądów i pomiarów bezpieczeństwa instalacji elektrycznych" />
                    <meta name="keywords" content="automatyka, system automatyki" />
                    <link rel="canonical" href="https://raz-dwa.pl/instalacje-elektryczne/pomiary" />
                    <title>Raz Dwa - Instalacje elektryczne - Automatyka</title>
                </Helmet>

                <section className='withBanner'>
                    <p className='h3'>Automatyka</p>
                    <img src={require('../../gfx/elektryka/automatyka.jpg')} alt='Automatyka' width='960' height='520' />
                    <p>Jesteśmy autoryzowanym instalatorem automatyki włoskiego producenta marki Bft. Producent ten zaistniał na włoskim rynku w 1981 roku a obecnie firma Bft jest aktywna na całym świecie, posiada 20 filii i ponad 530 dystrybutorów działających na terenie ponad 120 krajów. Nowoczesne rozwiązania i dążenie do ulepszania produktu sprawiły, że automatyka Bft to szereg zaawansowanych rozwiązań dzięki czemu marka jest jedną z najlepszych na rynku.</p>
                </section>

                <section className='gridStack'>
                    <div className='container'>
                        <div className='item'>
                            <div className='left'>
                                <img src={require('../../gfx/automatyka/mieszkalne.jpg')} alt='Systemy automatyki do budynków mieszkalnych' width='465' height='349' />
                            </div>
                            <div className='right'>
                                <p><strong><a href="https://www.bft-automation.com/pl_PL/systemy-automatyki-do-budynkow-mieszkalnychhome-access-automation/" rel="noreferrer noopener" target="_blank">Systemy automatyki do budynków mieszkalnych</a></strong></p>
                                <p>Szeroki wybór innowacyjnych produktów odpowiadających różnym potrzebom, które firma Bft zaprojektowała z myślą o automatyzacji dostępu dla domu. Łatwe w instalacji i intuicyjne w użyciu systemy automatyzacji dostępu dla domów są efektem trwających badań technologicznych i podejścia skoncentrowanego na indywidualnych zastosowaniach, na czym zyskuje instalator i sam użytkownik końcowy.</p>
                                <button>Więcej</button>
                            </div>
                        </div>
                        <div className='item reverse'>
                            <div className='left'>
                                <img src={require('../../gfx/automatyka/komercyjne.jpg')} alt='Systemy automatyki do zastosowań komercyjnych' width='465' height='349' />
                            </div>
                            <div className='right'>
                                <p><strong><a href="https://www.bft-automation.com/pl_PL/systemy-automatyki-do-zastosowan-komercyjnychbuilding-access-automation/" rel="noreferrer noopener" target="_blank">Systemy automatyki do zastosowań komercyjnych</a></strong></p>
                                <p>Najnowocześniejsze rozwiązania, które spełniają wszystkie operacyjne i techniczne wymagania instalatorów zaangażowanych w sektor automatyzacji dostępu dla przedsiębiorstw. W przeciągu wielu lat został opracowany i stworzyny szereg zaawansowanych produktów z oprogramowaniem zapewniającym obsługę zdalną nawet przez siedem osób jednocześnie.</p>
                                <button>Więcej</button>
                            </div>
                        </div>
                        <div className='item'>
                            <div className='left'>
                                <img src={require('../../gfx/automatyka/miejskiego.jpg')} alt='Systemy automatyki do użytku miejskiego' width='465' height='349' />
                            </div>
                            <div className='right'>
                                <p><strong><a href="https://www.bft-automation.com/pl_PL/systemy-automatyki-do-uzytku-miejskiegourban-access-automation/" rel="noreferrer noopener" target="_blank">Systemy automatyki do użytku miejskiego</a></strong></p>
                                <p>Systemy do zarządzania parkingami, szlabanami, zaporami i sterowania dostępem do centrum miasta to tylko niektóre produkty Bft przeznaczone dla sektora automatyzacji dostępu dla miast. Dedykowane systemy do zarządzania ruchem ulicznym w miastach obejmują najnowszej generacji rozwiązania oparte na wielu innowacjach technologicznych.</p>
                                <button>Więcej</button>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}