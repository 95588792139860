/* eslint-disable no-useless-escape */
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import ReCAPTCHA from 'react-google-recaptcha';
import { GoogleMap, LoadScript } from '@react-google-maps/api';

let validated = false;

const containerStyle = {
    width: '100%',
    height: '400px'
};

const center = {
    lat: 53.31486762736596,
    lng: 17.937947996197774
};

export default class Contact extends Component {
    constructor() {
        super();
    }

    componentDidMount() {
        document.body.className = 'contact';
    }
    
    componentWillUnmount() {
        document.body.className = '';
    }

    onChange(value) {
        validated = value;
    }

    handleSubmit(e) {
        e.preventDefault();

        const data = new FormData(e.target),
            response = document.getElementById('response');

        response.classList.add('sending');
        response.innerHTML = 'Wysyłanie maila...';

        if (validated.length) {
            fetch('../scripts/contact.php', {
                method: 'POST',
                body: data,
            }).then(data => {
                if (data.status !== 200) {
                    response.className = '';
                    response.classList.add('error');
                    response.innerHTML = 'Mail nie został wysłany, spróbuj ponownie.';
                } else {
                    response.className = '';
                    response.classList.add('successful');
                    response.innerHTML = 'Mail został wysłany poprawnie.';
                }
            }).catch(() => {
                response.className = '';
                response.classList.add('error');
                response.innerHTML = 'Mail nie został wysłany, spróbuj ponownie.';
            });
        }
    }

    render() {
        return (
            <React.Fragment>
                {document.cookie.replace(/(?:(?:^|.*;\s*)AcceptedCookie\s*\=\s*([^;]*).*$)|^.*$/, '$1') !== 'false' &&
                    <div className="maps">
                        <LoadScript googleMapsApiKey="AIzaSyD0FeoTd-SQ_XB8V1T2et_g_ZpKJQw8jIs">
                            <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={11} />
                        </LoadScript>
                    </div>
                }

                <div className='container'>
                    <Helmet>
                        <meta name="description" content="Skontaktuj się z nami gdy potrzebujesz się dowiedzieć informacji, których nie udało Ci się znaleźć na stronie." />
                        <link rel="canonical" href="https://raz-dwa.pl/kontakt" />
                        <title>Raz Dwa - Kontakt</title>
                    </Helmet>

                    <div className='double'>
                        <section className='contact'>
                            <p>Dane kontaktowe</p>
                            <p><strong>Raz Dwa</strong>, <br className="hiddenMedium"/>Grzegorz Świerblewski</p>
                            <p>86-011 Koronowo, <br className="hiddenMedium"/>Gogolinek 28</p>
                            <p><strong>Biuro sprzedaży:</strong></p>
                            <p>86-008 Koronowo, <br className="hiddenMedium"/>ul. Jana Sobieskiego 12</p>
                            <p>NIP: 554-228-48-12</p>
                            <p><a href='mailto:biuro@raz-dwa.pl'><span className='icon-envelope'></span> <em>biuro@raz-dwa.pl</em></a></p>
                            <p><a href='tel:+48783698986'><span className='icon-phone'></span> <em>783 698 986</em></a></p>
                        </section>
            
                        <section className='form'>
                            <p>Formularz kontaktowy</p>
                            {document.cookie.replace(/(?:(?:^|.*;\s*)AcceptedCookie\s*\=\s*([^;]*).*$)|^.*$/, '$1') !== 'false' ?
                                <form onSubmit={this.handleSubmit}>
                                    <div className='field required firstname'>
                                        <label htmlFor='firstname'><em>*</em> Imię</label>
                                        <input type='text' id='firstname' name='firstname' maxLength='63' required/>
                                    </div>
                                    <div className='field required lastname'>
                                        <label htmlFor='lastname'><em>*</em> Nazwisko</label>
                                        <input type='text' id='lastname' name='lastname' maxLength='63' required/>
                                    </div>
                                    <div className='field email'>
                                        <label htmlFor='email'><em>*</em> E-mail</label>
                                        <input type='email' id='email' name='email' maxLength='256' required/>
                                    </div>
                                    <div className='field required phone'>
                                        <label htmlFor='phone'><em>*</em> Telefon</label>
                                        <input type='tel' id='phone' name='phone' maxLength='12' required/>
                                    </div>
                                    <div className='field required message'>
                                        <label id='message'><em>*</em> Wiadomość (min. 30 znaków)</label>
                                        <textarea id='message' name='message' required></textarea>
                                    </div>
                                    <ReCAPTCHA
                                        className='g-recaptcha'
                                        sitekey= { process.env.NODE_ENV === 'development' ?
                                            '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI' :
                                            '6LfTfYgaAAAAANs9BxrBlsT9NqS1H-k1eWJW46IR'}
                                        onChange={this.onChange}
                                    />
                                    <div className='field required checkbox'>
                                        <input type='checkbox' value='terms' required/>
                                        <label><em>*</em> Akceptuję <a href='/polityka-prywatnosci' target='_blank' rel='nofollow'>politykę prywatności</a>.</label>
                                    </div>
                                    <label><em>*</em> Pole obowiązkowe</label>
                                    <div id="response"></div>
                                    <p>
                                        <button type='submit' name='submit'>Wyślij</button>
                                    </p>
                                </form>
                                : <p>Do poprawnego działania formularza potrzebujesz zaakceptować ciasteczka</p>
                            }
                        </section>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}